import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPaymentEnabled,
  setVideoBriefEnabled,
  setVideoDetailsEnabled,
  setShowToaster,
  setToasterMessage,
  setToasterType,
  setUserBrands,
} from "../../store/Slice/mainSlice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCreateTasksMutation,
  useGetUserBrandsQuery,
  useUpdateTaskMutation,
} from "../../store/Services/API";

function BottomNavbar({ videoPrice }) {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const videoDetailsEnabaled = useSelector(
    (state) => state.mainSlice.videoDetailsEnabaled
  );
  const isLoggedIn = useSelector((state) => state.mainSlice.isLoggedIn);
  const videoBriefEnabled = useSelector(
    (state) => state.mainSlice.videoBriefEnabled
  );
  const selectedVideoTypeId = useSelector(
    (state) => state.mainSlice.selectedVideoTypeId
  );
  const selectedVideoDurationId = useSelector(
    (state) => state.mainSlice.selectedVideoDurationId
  );
  const numberOfVideos = useSelector((state) => state.mainSlice.numberOfVideos);
  const videoAdditionalDetails = useSelector(
    (state) => state.mainSlice.videoAdditionalDetails
  );
  const videoScenes = useSelector((state) => state.mainSlice.videoScenes);
  const videoMentions = useSelector((state) => state.mainSlice.videoMentions);
  const videoContentLinks = useSelector(
    (state) => state.mainSlice.videoContentLinks
  );
  const productVideoUrl = useSelector(
    (state) => state.mainSlice.productVideoUrl
  );
  const selectedVideoAspectRatio = useSelector(
    (state) => state.mainSlice.selectedVideoAspectRatio
  );
  const selectedInHouseServicesId = useSelector(
    (state) => state.mainSlice.selectedInHouseServicesId
  );
  const selectedCreatorId = useSelector(
    (state) => state.mainSlice.selectedCreatorId
  );
  const userBrands = useSelector((state) => state.mainSlice.userBrands);
  const selectedUserBrand = useSelector(
    (state) => state.mainSlice.selectedUserBrand
  );
  const selectedCreatorCriteriaTags = useSelector(
    (state) => state.mainSlice.selectedCreatorCriteriaTags
  );
  const selectedAuthenticPhotosId = useSelector(
    (state) => state.mainSlice.selectedAuthenticPhotosId
  );
  const [createTask] = useCreateTasksMutation();
  const [updateTask] = useUpdateTaskMutation()
  const { data: usersBrands, isFetching: isUserBrandsFetching } =
    useGetUserBrandsQuery(null, {
      skip: !isLoggedIn,
    });

  const dispatch = useDispatch();

  const handleNext = () => {
    if (videoDetailsEnabaled) {
      dispatch(setVideoDetailsEnabled(false));
      dispatch(setVideoBriefEnabled(true));
    } else if (videoBriefEnabled) {
      dispatch(setVideoDetailsEnabled(true));
      dispatch(setVideoBriefEnabled(false));
    }
  };

  const jobCompleted = async () => {
    const formData = new FormData();
    formData.append("video_type_id", selectedVideoTypeId);
    formData.append("video_duration_id", selectedVideoDurationId);
    formData.append("number_of_videos", numberOfVideos);
    formData.append("additional_details", videoAdditionalDetails);
    formData.append("scenes", JSON.stringify(videoScenes));
    formData.append("authentic_photo_id", selectedAuthenticPhotosId);
    formData.append("mentions", JSON.stringify(videoMentions));
    formData.append("content_examples", JSON.stringify(videoContentLinks));
    formData.append("video", productVideoUrl);
    formData.append("brand_id", userBrands[selectedUserBrand].id);
    formData.append("aspect_ratio", selectedVideoAspectRatio);
    formData.append("in_house_service_id", selectedInHouseServicesId);
    formData.append("creator_id", selectedCreatorId);
    formData.append(
      "criteriaTags",
      JSON.stringify(selectedCreatorCriteriaTags)
    );
    let response = null;
    try {
      if (
        location.pathname !==
        `/brands/campaigns/${location.pathname.split("/")[3]}/info/edit`
      ) {
        response = await createTask(formData);
      } else {
        response = await updateTask({data:formData, id:location.pathname.split("/")[3]});
      }
      if (response.data.status) {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Success"));
        setTimeout(() => {
          navigate("/brands/dashboard");
        }, 1000);
        setLoader(false);
        dispatch(setVideoDetailsEnabled(true));
        dispatch(setPaymentEnabled(false));
        dispatch(setVideoBriefEnabled(false));
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Error"));
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!isUserBrandsFetching) {
      dispatch(setUserBrands(usersBrands?.data));
    }
  }, [isUserBrandsFetching]);

  return (
    <div className={`p-4 bg-white fixed bottom-0 border-t w-full z-30 left-0`}>
      <div className="float-right">
        <div className="flex gap-10 relative">
          <div>
            <p className="text-[#817EFD]">
              {videoPrice.numberOfVideos} video x $
              {Number(videoPrice.videoType) +
                Number(videoPrice.videoDuration) +
                Number(videoPrice.inHouseServices) +
                Number(videoPrice.authenticPhotos) +
                Number(videoPrice.creators)}
            </p>
            <p className="font-semibold">
              Total:{" "}
              <span className="text-[#817EFD] font-normal">
                $
                {Number(videoPrice.numberOfVideos) *
                  (Number(videoPrice.videoType) +
                    Number(videoPrice.videoDuration) +
                    Number(videoPrice.inHouseServices) +
                    Number(videoPrice.authenticPhotos) +
                    Number(videoPrice.creators))}
              </span>
            </p>
          </div>
          <div className="w-[1px] border-l border-gray-500" />
          <div className="flex flex-col justify-center">
            {!videoBriefEnabled &&
            location.pathname !==
              `/brands/campaigns/${
                location.pathname.split("/")[3]
              }/info/edit` ? (
              <button
                className="py-1 px-4 bg-[#817efd] text-white rounded-full"
                onClick={() => handleNext()}
              >
                Next
              </button>
            ) : (
              <button
                className="py-1 px-4 bg-[#817efd] text-white rounded-full"
                onClick={() => jobCompleted()}
              >
                {location.pathname !==
                `/brands/campaigns/${location.pathname.split("/")[3]}/info/edit`
                  ? "Done"
                  : "Update"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomNavbar;
