import React from "react";
import MasterLayout from "../../../Components/Common/Layout/MasterLayout";
import { Link } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { ThreeCircles } from "react-loader-spinner";
import { useGetBrandCategoriesQuery } from "../../../store/Services/API";

function BrandCategories() {
  const { data, isFetching } = useGetBrandCategoriesQuery();

  return (
    <MasterLayout>
      <div className="shadow-lg bg-white">
        <h4 className="px-5 py-5 flex items-center justify-between uppercase">
          Brand Categories
          <Link to="/admin/brand-categories/add">
            <MdAdd className="bg-[#817efd] text-white rounded-full cursor-pointer" />
          </Link>
        </h4>
        <div className="overflow-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {!isFetching ? (
                <>
                  {data && data?.data?.length > 0 ? (
                    data?.data?.map((x) => (
                      <tr className="bg-white border-b bg-white" key={x.id}>
                        <td className="px-6 py-4">{x.name}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="px-6 py-4 text-center" colSpan="5">
                        No data available
                      </td>
                    </tr>
                  )}
                </>
              ) : (
                <tr>
                  <td className="px-6 py-4 text-center" colSpan="5">
                    <ThreeCircles
                      visible={true}
                      height="50"
                      width="50"
                      color="#000"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{}}
                      wrapperClass="flex justify-center"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </MasterLayout>
  );
}

export default BrandCategories;
