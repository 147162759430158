export const Role = {
  ADMIN: "admin",
  USER: "user",
  GUEST: "guest",
};

export const DecisionType = {
  ALLOW: "allow",
  DENY: "deny",
  REDIRECT: "redirect",
};
