import { configureStore, combineReducers } from "@reduxjs/toolkit";
import mainSlice from "./Slice/mainSlice";
import { API } from "./Services/API";

const rootReducer = combineReducers({
  mainSlice: mainSlice,
  [API.reducerPath]: API.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(API.middleware),
});
