import React from "react";
import MasterLayout from "../../../Components/Common/Layout/MasterLayout";
import { Link } from "react-router-dom";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { ThreeCircles } from "react-loader-spinner";
import {
  useCategoryStatusMutation,
  useGetCategoriesQuery,
} from "../../../store/Services/API";
import { useDispatch } from "react-redux";
import {
  setCreatorCriteriaToEdit,
  setShowToaster,
  setToasterMessage,
  setToasterType,
} from "../../../store/Slice/mainSlice";

function Category() {
  const { data, isFetching } = useGetCategoriesQuery();
  const [CreatorStatus] = useCategoryStatusMutation();
  const dispatch = useDispatch();

  const handleStatus = async (status, id) => {
    try {
      const response = await CreatorStatus({ status: status, id: id });
      if (response?.data?.status) {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response?.data?.message));
        dispatch(setToasterType("Success"));
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response?.data?.message));
        dispatch(setToasterType("Error"));
      }
    } catch (error) {
      console.log(error);
      dispatch(setShowToaster(true));
      dispatch(
        setToasterMessage("Something went wrong. Please try again later.")
      );
      dispatch(setToasterType("Error"));
    }
  };

  return (
    <MasterLayout>
      <div className="shadow-lg bg-white">
        <h4 className="px-5 py-5 flex items-center justify-between uppercase">
          Category
          <Link to="/admin/category/add">
            <MdAdd className="bg-[#817efd] text-white rounded-full cursor-pointer" />
          </Link>
        </h4>
        <div className="overflow-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!isFetching ? (
                <>
                  {data && data?.data?.length > 0 ? (
                    data?.data?.map((x) => (
                      <tr className="bg-white border-b bg-white" key={x.id}>
                        <td className="px-6 py-4">{x.name}</td>
                        <td className="px-6 py-4">
                          {x?.status === 1 ? (
                            <button
                              className="bg-[#198754] text-white py-2 px-3 rounded-lg"
                              onClick={() => handleStatus(0, x.id)}
                            >
                              Active
                            </button>
                          ) : (
                            <button
                              className="bg-[#dc3545] text-white py-2 px-3 rounded-lg"
                              onClick={() => handleStatus(1, x.id)}
                            >
                              In Active
                            </button>
                          )}
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex gap-4">
                            <Link
                              to={`/admin/add-category-media/${x.id}`}
                              className="bg-[#817efd] text-white px-3 py-2 rounded-lg"
                            >
                              Add Media
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="px-6 py-4 text-center" colSpan="5">
                        No data available
                      </td>
                    </tr>
                  )}
                </>
              ) : (
                <tr>
                  <td className="px-6 py-4 text-center" colSpan="5">
                    <ThreeCircles
                      visible={true}
                      height="50"
                      width="50"
                      color="#000"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{}}
                      wrapperClass="flex justify-center"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </MasterLayout>
  );
}

export default Category;
