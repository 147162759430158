import React, { useState } from 'react'
import AuthLayout from '../../Components/Common/Layout/AuthScreensLayout'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useResetPasswordMutation } from '../../store/Services/API';
import { setShowToaster, setToasterMessage, setToasterType } from '../../store/Slice/mainSlice';
import Input from '../../Components/Common/Form/Input/Input';
import { confirmPassword, registerPassword } from '../../Utils/InputValidations';
import Button from '../../Components/Common/Button';

function ForgotPassword() {
    const [loader, setLoader] = useState(false);
    const methods = useForm();
    const dispatch = useDispatch();
    const [resetPassword] = useResetPasswordMutation();
    const userEmail = useSelector((state) => state.mainSlice.userEmail);
    const navigate = useNavigate();
    const onSubmit = async (data) => {
        setLoader(true);
        const formData = new FormData()
        formData.append("email", userEmail);
        formData.append("password", data.password);
        try {
          const response = await resetPassword(formData);
          if (response?.data?.status) {
            dispatch(setShowToaster(true));
            dispatch(setToasterMessage(response.data.message));
            dispatch(setToasterType("Success"));
            navigate('/login', { state: { url: `login` } })
            setLoader(false);
          } else {
            dispatch(setShowToaster(true));
            dispatch(setToasterMessage(response.data.message));
            dispatch(setToasterMessage("Error"));
            setLoader(false);
          }
        } catch (error) {
          console.log("hello");
          dispatch(setShowToaster(true));
          dispatch(
            setToasterMessage("Something went wrong. Please try again later.")
          );
          dispatch(setToasterType("Error"));
          setLoader(false);
        }
      };
  return (
    <AuthLayout title="Reset Password">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="col-span-6">
              <Input {...registerPassword} />
            </div>
            <div className="col-span-6">
              <Input {...confirmPassword} />
            </div>
            <Button loader={loader} text='Reset' />
        </form>
        </FormProvider>
    </AuthLayout>
  )
}

export default ForgotPassword