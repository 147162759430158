import React from "react";
import ReactPlayer from "react-player";
import { MdPlayArrow } from "react-icons/md";

function VideoThumbnail({ url, openPlayer }) {
  return (
    <div className="relative shadow-lg">
      <MdPlayArrow fontSize='30px' className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] cursor-pointer z-30" onClick={() => openPlayer(url)} />
      <ReactPlayer
        width="100%"
        height="100%"
        style={{ objectFit: "fill" }}
        url={`${!url.includes('localhost') ? process.env.REACT_APP_ASSET_URL : ''}${url}`}
      />
    </div>
  );
}

export default VideoThumbnail;
