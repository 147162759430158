// PhoneInput.js
import React from "react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CustomPhoneInput = ({ control, name, rules, id, label }) => {
  return (
    <>
      <label className="text-base text-black mb-1" htmlFor={id}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div>
            <PhoneInput
              country={"us"}
              value={value}
              onChange={onChange}
              containerClass=""
              inputClass={`!bg-[#F9F9F9] !border !border-[#CCCCCC] !rounded-lg !shadow-none !outline-none !w-full !text-sm !text-[#989898] !h-12 pl-2 ${
                error ? "border-red-500" : ""
              }`}
            />
            {error && <p className="text-red-500 text-sm">{error.message}</p>}
          </div>
        )}
      />
    </>
  );
};

export default CustomPhoneInput;
