import React from "react";

function DataCard({ title, price, active, image, description, ratio }) {
  return (
    <div
      className={`shadow-xl p-6 rounded-xl cursor-pointer bg-white h-full ${
        active && "border-2 border-[#817EFD]"
      }`}
    >
      <div className="flex justify-between items-start">
        <div className="">
          <div className="flex gap-2 items-center">
            {image !== "undefined" && image !== undefined && (
              <img
                src={`${process.env.REACT_APP_ASSET_URL}${image}`}
                alt={title}
                className="w-5 h-5"
              />
            )}
            <h5 className="text-xl font-semibold leading-10">
              {title}{" "}
              {ratio && <span className="text-sm font-normal">({ratio})</span>}{" "}
            </h5>
          </div>
          {price !== "undefined" && price !== undefined && (
            <h3 className="text-[#817EFD] text-2xl leading-10">
              ${price}
              <span className="text-black text-[15px]"> / each video</span>{" "}
            </h3>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
        <div
          className={`w-5 h-5 rounded-full border border-gray-400 border-4 ${
            active && "!border-[#817EFD]"
          }`}
        ></div>
      </div>
    </div>
  );
}

export default DataCard;
