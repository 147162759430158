import React, { useEffect, useState } from "react";
import Card from "../MainCard";
import { useGetInHouseServicesQuery } from "../../../../store/Services/API";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedInHouseServicesId } from "../../../../store/Slice/mainSlice";
import VideoThumbnail from "../VideoThumbnail";
import ModalLayout from "../../Layout/ModelLayout";
import { MdClose } from "react-icons/md";
import VideoPlayer from "../../VideoPlayer";
import { TiTick } from "react-icons/ti";

function InHouseServices({ setVideoPrice, inHouseServicesId = null }) {
  const { data, isFetching } = useGetInHouseServicesQuery();
  const [inHouseServices, setInHouseServices] = useState([]);
  const inHouseServiceId = useSelector(
    (state) => state.mainSlice.selectedInHouseServicesId
  );
  const dispatch = useDispatch();
  const [isPlayer, setIsPlayer] = useState({
    open: false,
    url: "",
  });

  const updatePlayerState = (newState) => {
    setIsPlayer((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const openPlayer = (url) => {
    updatePlayerState({ open: true, url });
  };

  const closePlayer = () => {
    updatePlayerState({ open: false, url: "" });
  };

  const handleInHouseServices = (id, price) => {
    dispatch(setSelectedInHouseServicesId(id));
    setVideoPrice((prevState) => ({
      ...prevState,
      inHouseServices: price,
    }));
  };

  useEffect(() => {
    if (data?.data) {
      setInHouseServices(data?.data);
      if (inHouseServicesId === null) {
        dispatch(
          setSelectedInHouseServicesId(
            data?.data?.find((x) => x.status === 1).id
          )
        );
        setVideoPrice((prevState) => ({
          ...prevState,
          inHouseServices: data?.data[0]?.price,
        }));
      }
    }
  }, [data, data?.data, dispatch, isFetching, inHouseServiceId]);

  useEffect(() => {
    if (data?.data) {
      setInHouseServices(data?.data);
      if (inHouseServicesId !== null) {
        dispatch(
          setSelectedInHouseServicesId(
            data?.data?.find((x) => x.id === inHouseServicesId).id
          )
        );
        setVideoPrice((prevState) => ({
          ...prevState,
          inHouseServices: data?.data?.find((x) => x.id === inHouseServicesId)
            ?.price,
        }));
      }
    }
  }, [data, data?.data, dispatch, isFetching, inHouseServicesId]);

  return (
    <Card title="In-house services">
      <div className="grid grid-cols-12">
        {inHouseServices?.map((x) => (
          <>
            {x.status !== 0 && (
              <div
                className="col-span-12 sm:col-span-3 flex flex-col gap-16 shadow-xl p-4 rounded-lg"
                key={x.id}
                onClick={() => handleInHouseServices(x.id, x.price)}
              >
                <div className="flex flex-col gap-4">
                  <>
                    {x?.medias?.map((y) => (
                      <VideoThumbnail
                        url={y.filename}
                        openPlayer={openPlayer}
                        key={y.id}
                      />
                    ))}
                  </>
                  <div>
                    <h4 className="text-2xl">{x.name}</h4>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: x?.description,
                    }}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-2xl font-semibold">
                    ${x.price}
                    <span className="text-[15px] font-normal text-gray-500">
                      /each video
                    </span>
                  </p>
                  <button
                    className={`border border-gray-400 rounded-full px-3 py-1 text-gray-400 ${
                      inHouseServiceId === x.id &&
                      "!border-[#817EFD] !text-[#817EFD]"
                    }`}
                  >
                    {inHouseServiceId === x.id ? (
                      <span className="flex items-center gap-2">
                        <TiTick /> Included
                      </span>
                    ) : (
                      `Include`
                    )}
                  </button>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
      {isPlayer.open && (
        <ModalLayout classes="!p-0">
          <div className="flex justify-end pt-3 pr-3">
            <MdClose
              fontSize="20px"
              className="cursor-pointer"
              onClick={() => closePlayer()}
            />
          </div>
          <VideoPlayer url={isPlayer.url} />
        </ModalLayout>
      )}
    </Card>
  );
}

export default InHouseServices;
