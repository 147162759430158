import React, { useEffect, useState } from "react";
import Card from "../MainCard";
import DataCard from "../DataCard";
import { useGetVideoTypeQuery } from "../../../../store/Services/API";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVideoTypeId } from "../../../../store/Slice/mainSlice";
import VideoThumbnail from "../VideoThumbnail";
import ModalLayout from "../../Layout/ModelLayout";
import VideoPlayer from "../../VideoPlayer";
import { MdClose } from "react-icons/md";

function VideoType({ setVideoPrice, videoTypeId = null }) {
  const { data, isFetching } = useGetVideoTypeQuery();
  const [videoType, setVideoType] = useState([]);
  const videoId = useSelector((state) => state.mainSlice.selectedVideoTypeId);
  const dispatch = useDispatch();
  const [selectedVideoType, setSelectedVideoType] = useState({});
  const [isPlayer, setIsPlayer] = useState({
    open: false,
    url: "",
  });

  const getDescriptionAndMedia = (object) => {
    let data = object?.find((x) => {
      return x.id === videoId && x.description;
    });
    setSelectedVideoType(data);
  };

  const updatePlayerState = (newState) => {
    setIsPlayer((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const openPlayer = (url) => {
    updatePlayerState({ open: true, url });
  };

  const closePlayer = () => {
    updatePlayerState({ open: false, url: "" });
  };

  const handleVideoType = (id, price) => {
    dispatch(setSelectedVideoTypeId(id));
    setVideoPrice((prevState) => ({
      ...prevState,
      videoType: price,
    }));
  };

  useEffect(() => {
    if (data?.data) {
      setVideoType(data?.data);
      if (videoTypeId === null) {
        dispatch(
          setSelectedVideoTypeId(data?.data?.find((x) => x.status === 1).id)
        );
        getDescriptionAndMedia(data?.data);
        setVideoPrice((prevState) => ({
          ...prevState,
          videoType: data?.data[0]?.price,
        }));
      }
    }
  }, [data, data?.data, dispatch, isFetching, videoId]);

  useEffect(() => {
    if (data?.data) {
      setVideoType(data?.data);
      if (videoTypeId !== null && videoTypeId !== "") {
        console.log("hello")
        dispatch(
          setSelectedVideoTypeId(
            data?.data?.find((x) => x.id === videoTypeId).id
          )
        );
        getDescriptionAndMedia(data?.data);
        setVideoPrice((prevState) => ({
          ...prevState,
          videoType: data?.data?.find((x) => x.id === videoTypeId).price,
        }));
      }
    }
  }, [data, data?.data, dispatch, isFetching, videoTypeId]);

  return (
    <div>
      <Card title="Video Type">
        <div className="grid grid-cols-12 gap-4 mt-4">
          {videoType.length > 0 &&
            videoType?.map((x) => (
              <>
                {x.status !== 0 && (
                  <div
                    className="col-span-12 sm:col-span-3"
                    key={x.id}
                    onClick={() => handleVideoType(x.id, x.price)}
                  >
                    <DataCard
                      title={x.name}
                      price={x.price}
                      active={x.id === videoId}
                    />
                  </div>
                )}
              </>
            ))}
        </div>
        <div className="grid grid-cols-12 gap-4 mt-4">
          <div className="col-span-6">
            <div
              dangerouslySetInnerHTML={{
                __html: selectedVideoType?.description,
              }}
            />
          </div>
          <div className="col-span-6 flex">
            {selectedVideoType?.medias?.map((x, i) => (
              <div
                key={x.id}
                className={`first:-right-[47px] relative odd:z-10 even:z-20 last:-left-[47px] ${
                  i > 2 && "hidden"
                }`}
              >
                {i <= 2 && (
                  <div>
                    <VideoThumbnail url={x.filename} openPlayer={openPlayer} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Card>
      {isPlayer.open && (
        <ModalLayout classes="!p-0">
          <div className="flex justify-end pt-3 pr-3">
            <MdClose
              fontSize="20px"
              className="cursor-pointer"
              onClick={() => closePlayer()}
            />
          </div>
          <VideoPlayer url={isPlayer.url} />
        </ModalLayout>
      )}
    </div>
  );
}

export default VideoType;
