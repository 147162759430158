import React, { useState } from "react";
import MasterLayout from "../../../Components/Common/Layout/MasterLayout";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setShowToaster,
  setToasterMessage,
  setToasterType,
} from "../../../store/Slice/mainSlice";
import { useVideoAspectRatioAddMutation } from "../../../store/Services/API";
import Input from "../../../Components/Common/Form/Input/Input";
import {
  videoAspectRatio,
  videoAspectRatioMedia,
  videoAspectRatioName,
} from "../../../Utils/InputValidations";
import TextEditor from "../../../Components/Common/Form/TextEditor/TextEditor";
import { MdClose } from "react-icons/md";
import Button from "../../../Components/Common/Button";

function VideoAspectRatioAddAdmin() {
  const [selectedImage, setSelectedImage] = useState([]);
  const [loader, setLoader] = useState(false);
  const [addVideoAspectRatio] = useVideoAspectRatioAddMutation();
  const videoTypeToEdit = useSelector(
    (state) => state.mainSlice.videoTypeToEdit
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImage(files);
  };

  const methods = useForm();

  const getDescription = (value) => {
    methods.setValue("description", value);
  };

  const onSubmit = async (data) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("heading", data.name);
    formData.append("ratio", data.ratio);
    formData.append("description", data.description);
    selectedImage.forEach((tag, index) => {
      formData.append(`image`, tag);
    });
    try {
      const response = await addVideoAspectRatio(formData);
      if (response.data.status) {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Success"));
        navigate(`/admin/video-aspect-ratio`);
        setLoader(false);
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Error"));
        setLoader(false);
      }
    } catch (error) {
      dispatch(setShowToaster(true));
      dispatch(
        setToasterMessage("Something went wrong. Please try again later.")
      );
      dispatch(setToasterType("Error"));
      setLoader(false);
    }
  };

  return (
    <MasterLayout>
      <div className="bg-white p-4 shadow-lg">
        <h5 className="mb-10">Add Aspect Ratio</h5>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 sm:col-span-6">
                <Input {...videoAspectRatioName} />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Input {...videoAspectRatio} />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Input
                  {...videoAspectRatioMedia}
                  handleImageChange={handleImageChange}
                />
              </div>
              {selectedImage.length > 0 && (
                <div className="col-span-12 sm:col-span-6">
                  <label className="">Selected Media</label>
                  <div className="flex mt-2 gap-3">
                    {selectedImage.map((file, index) => (
                      <div key={index} className={`${index > 2 && "hidden"}`}>
                        {index <= 2 && (
                          <div className="relative">
                            <MdClose
                              className="absolute -left-2 -top-2 z-40 bg-[#dc3545] text-white p-1 rounded-full cursor-pointer"
                              fontSize="20px"
                              onClick={() => {
                                setSelectedImage((prev) =>
                                  prev.filter((_, i) => i !== index)
                                );
                              }}
                            />
                            <img
                              className="w-full h-32"
                              src={URL.createObjectURL(file)}
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="col-span-12">
                <TextEditor
                  getDescription={getDescription}
                  value={videoTypeToEdit?.description}
                />
              </div>
              <div className="col-span-12">
                <div className="sm:w-24 ml-auto">
                  <Button text="Add" loader={loader} />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </MasterLayout>
  );
}

export default VideoAspectRatioAddAdmin;
