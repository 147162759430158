import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';

function TextEditor({ getDescription, value }) {
    const [editorValue, setEditorValue] = useState('');

    useEffect(() => {
        if (getDescription) {
            getDescription(editorValue)
        }
    }, [editorValue, getDescription])

    useEffect(() => {
      if(value){
        setEditorValue(value)
      }
    }, [])
    

    return (
        <ReactQuill
            value={editorValue}
            onChange={(value) => setEditorValue(value)}
        />
    );
}

export default TextEditor;
