import React from "react";
import { ThreeCircles } from "react-loader-spinner";

function Button({ loader, text }) {
  return (
    <button
      type="submit"
      disabled={loader}
      className="w-full bg-[#817EFD] shadow-lg rounded-lg h-12 text-white mt-5 disabled:bg-[#817efd]/75"
    >
      {loader ? (
        <ThreeCircles
          visible={true}
          height="20"
          width="20"
          color="#fff"
          ariaLabel="three-circles-loading"
          wrapperStyle={{}}
          wrapperClass="flex justify-center"
        />
      ) : (
        `${text}`
      )}
    </button>
  );
}

export default Button;
