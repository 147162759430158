import React, { useState } from "react";
import ReactPlayer from "react-player";

function VideoPlayer({ url }) {
  const [playing, setPlaying] = useState(true);
  return (
    <ReactPlayer
      width="100%"
      height="100%"
      style={{ objectFit: "fill" }}
      playing={playing}
      controls
      url={`${!url.includes('localhost') ? process.env.REACT_APP_ASSET_URL : ''}${url}`}
    />
  );
}

export default VideoPlayer;
