import React, { useState } from "react";
import MasterLayout from "../../../Components/Common/Layout/MasterLayout";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddCategoriesMediaMutation } from "../../../store/Services/API";
import { videoTypeMedia } from "../../../Utils/InputValidations";
import { setShowToaster, setToasterMessage, setToasterType } from "../../../store/Slice/mainSlice";
import Input from "../../../Components/Common/Form/Input/Input";
import Button from "../../../Components/Common/Button";

function UploadCategoryMedia() {
    const [loader, setLoader] = useState(false);
    const [selectedImage, setSelectedImage] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [addCategoryMedia] = useAddCategoriesMediaMutation();
    const methods = useForm();
    const location = useLocation()
    

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedImage(files);
      };
  
    const onSubmit = async (data) => {
      setLoader(true);
      const formData = new FormData();
      formData.append("category_id", location.pathname.split('/')[3]);
      selectedImage.forEach((tag, index) => {
        formData.append(`video[${index}]`, tag);
      });
      try {
        const response = await addCategoryMedia(formData);
        if (response.data.status) {
          dispatch(setShowToaster(true));
          dispatch(setToasterMessage(response.data.message));
          dispatch(setToasterType("Success"));
          navigate(`/admin/categories`);
          setLoader(false);
        } else {
          dispatch(setShowToaster(true));
          dispatch(setToasterMessage(response.data.message));
          dispatch(setToasterType("Error"));
          setLoader(false);
        }
      } catch (error) {
        dispatch(setShowToaster(true));
        dispatch(
          setToasterMessage("Something went wrong. Please try again later.")
        );
        dispatch(setToasterType("Error"));
        setLoader(false);
      }
    };
  return (
    <MasterLayout>
      <div className="bg-white p-4 shadow-lg">
        <h4 className="uppercase">Add Media</h4>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 sm:col-span-6">
              <Input
                  {...videoTypeMedia}
                  handleImageChange={handleImageChange}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <div className="sm:w-24 ml-auto">
                  <Button text="Add" loader={loader} />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </MasterLayout>
  );
}

export default UploadCategoryMedia;
