import React from "react";
import MasterLayout from "../../Components/Common/Layout/MasterLayout";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";

function Brand() {
  const userBrands = useSelector((state) => state.mainSlice.userBrands);
  const selectedUserBrand = useSelector(
    (state) => state.mainSlice.selectedUserBrand
  );
  return (
    <MasterLayout>
      <div className="sm:p-5">
        <Link
          to={`/brands/edit/brand-detail`}
          className="font-medium w-fit px-4 py-2 bg-[#817efd] text-white rounded-full mb-4 flex items-center gap-2"
        >
          <MdEdit /> Edit
        </Link>
        <div className="bg-white shadow-lg p-5 max-w-4xl overflow-auto">
          <h5>Brand Information</h5>
          <table className="w-full">
            <tbody>
              <tr className="border-b">
                <th className="w-1/4 text-left font-medium px-6 py-3">Logo</th>
                <td className="px-6 py-3">
                  <div className="w-[100px] h-[100px] bg-[#00beff]/20 rounded-full flex flex-col justify-center items-center text-[36px] text-[#00beff]">
                    {userBrands[selectedUserBrand]?.logo ? (
                      <img
                        src={`${process.env.REACT_APP_ASSET_URL}${userBrands[selectedUserBrand]?.logo}`}
                        width="100%"
                        className="rounded-full"
                        alt=""
                      />
                    ) : (
                      <>{userBrands[selectedUserBrand]?.name.slice(0, 2)}</>
                    )}
                  </div>
                </td>
              </tr>
              <tr className="border-b">
                <th className="w-1/4 text-left font-medium px-6 py-3">Name</th>
                <td className="px-6 py-3">
                  {userBrands[selectedUserBrand]?.name}
                </td>
              </tr>
              <tr className="border-b">
                <th className="w-1/4 text-left font-medium px-6 py-3">
                  Category
                </th>
                <td className="px-6 py-3">
                  {userBrands[selectedUserBrand]?.brand_category?.name}
                </td>
              </tr>
              <tr className="border-b">
                <th className="w-1/4 text-left font-medium px-6 py-3">
                  Brand Country
                </th>
                <td className="px-6 py-3">
                  {userBrands[selectedUserBrand]?.country}
                </td>
              </tr>
              <tr className="border-b">
                <th className="w-1/4 text-left font-medium px-6 py-3">
                  Homepage
                </th>
                <td className="px-6 py-3">
                  {userBrands[selectedUserBrand]?.url}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </MasterLayout>
  );
}

export default Brand;
