import React, { useEffect, useState } from "react";
import MasterLayout from "../../Components/Common/Layout/MasterLayout";
import ProductVideo from "../../Components/Common/Campaign/VideoBrief/ProductVideo";
import VideoType from "../../Components/Common/Campaign/VideoDetails/VideoType";
import VideoQuantity from "../../Components/Common/Campaign/VideoDetails/VideoQuantity";
import VideoDuration from "../../Components/Common/Campaign/VideoDetails/VideoDuration";
import AspectRatio from "../../Components/Common/Campaign/VideoDetails/AspectRatio";
import InHouseServices from "../../Components/Common/Campaign/VideoDetails/InHouseServices";
import AuthenticPhotos from "../../Components/Common/Campaign/VideoDetails/AuthenticPhotos";
import BottomNavbar from "../../Components/Common/BottomNavbar";
import Creators from "../../Components/Common/Campaign/VideoDetails/CreatorCriteria/Creators";
import Card from "../../Components/Common/Campaign/MainCard";
import Criteria from "../../Components/Common/Campaign/VideoDetails/CreatorCriteria/Criteria";
import Scenario from "../../Components/Common/Campaign/VideoBrief/Scenario";
import { useLocation } from "react-router-dom";
import { useGetTaskDetailQuery } from "../../store/Services/API";
import { ThreeCircles } from "react-loader-spinner";

function EditTask() {
  const [taskDetail, setTaskDetail] = useState(null)
  const location = useLocation();
  const { data } = useGetTaskDetailQuery(location.pathname.split("/")[3]);
  const [videoPrice, setVideoPrice] = useState({
    videoType: 0,
    numberOfVideos: 1,
    videoDuration: 0,
    inHouseServices: 0,
    authenticPhotos: 0,
    creators: 0,
    subTotal: 0,
    total: 0,
  });

  useEffect(() => {
    if (data) {
      setTaskDetail(data?.data);
    }
  }, [data]);
  return (
    <MasterLayout>
      {data ? (
        <div className="flex flex-col gap-8">
          <ProductVideo />
          <VideoType
            videoTypeId={taskDetail?.video_type?.id}
            setVideoPrice={setVideoPrice}
          />
          <VideoQuantity
            setVideoPrice={setVideoPrice}
            NoOfVideos={taskDetail?.number_of_videos}
          />
          <VideoDuration
            setVideoPrice={setVideoPrice}
            DurationId={taskDetail?.video_duration?.id}
          />
          <AspectRatio
            setVideoPrice={setVideoPrice}
            aspectRatioId={taskDetail?.aspect_ratio?.id}
          />
          <InHouseServices
            setVideoPrice={setVideoPrice}
            inHouseServicesId={taskDetail?.in_house_service?.id}
          />
          <AuthenticPhotos
            setVideoPrice={setVideoPrice}
            authenticPhotosId={taskDetail?.authentic_photo?.id}
          />
          <Card title="Creator Criteria">
            <Creators
              setVideoPrice={setVideoPrice}
              creatorsId={taskDetail?.creator?.id}
            />
            <Criteria criteriaTagId={taskDetail?.creator_criteria_tag} />
          </Card>
          <Scenario
            addedMentions={taskDetail?.mentions}
            addedScenes={taskDetail?.scenes}
            addedContentLinks={taskDetail?.content_examples}
            addedadditionalDetails={taskDetail?.additional_details}
          />
        </div>
      ) : (
        <ThreeCircles
          visible={true}
          height="60"
          width="60"
          color="#000"
          ariaLabel="three-circles-loading"
          wrapperStyle={{}}
          wrapperClass="flex flex-col items-center h-screen justify-center"
        />
      )}
      <BottomNavbar videoPrice={videoPrice} />
    </MasterLayout>
  );
}

export default EditTask;
