import Brand from "../Pages/User/Brand";
import EditBrand from "../Pages/User/EditBrand";
import EditTask from "../Pages/User/EditTask";
import Tasks from "../Pages/User/Tasks";
import ViewTask from "../Pages/User/ViewTask";
import Dashboard from "../Pages/User/dashboard";
import { Role, DecisionType } from "./UserTypes";

export const UserRoutes = [
  {
    path: "/brands/dashboard",
    component: Dashboard,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.REDIRECT,
        meta: "/admin/dashboard"
      },
      [Role.USER]: {
        type: DecisionType.ALLOW,
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/brands/campaigns",
    component: Tasks,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.REDIRECT,
        meta: "/admin/dashboard"
      },
      [Role.USER]: {
        type: DecisionType.ALLOW,
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/brands/campaigns/:id/info/view",
    component: ViewTask,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.ALLOW,
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/brands/brand-detail",
    component: Brand,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.REDIRECT,
        meta: "/admin/dashboard"
      },
      [Role.USER]: {
        type: DecisionType.ALLOW,
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/brands/edit/brand-detail",
    component: EditBrand,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.REDIRECT,
        meta: "/admin/dashboard"
      },
      [Role.USER]: {
        type: DecisionType.ALLOW,
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/brands/campaigns/:id/info/edit",
    component: EditTask,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.ALLOW,
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
];
