import React, { useEffect, useState } from "react";
import { useGetCreatorTagsQuery } from "../../../../../store/Services/API";
import { useDispatch, useSelector } from "react-redux";
import { addCriteriaTag, removeCriteriaTag } from "../../../../../store/Slice/mainSlice";

function Criteria({ criteriaTagId = null }) {
  const { data, isFetching } = useGetCreatorTagsQuery();
  const [creatorTags, setCreatorTags] = useState([]);
  const dispatch = useDispatch();
  const selectedCreatorCriteriaTags = useSelector(
    (state) => state.mainSlice.selectedCreatorCriteriaTags
  );

  const handleCreatorCriteriaTags = (parentId, criteriaId) => {
    const index = selectedCreatorCriteriaTags.findIndex(
      (tag) =>
        tag.tag_heading_id === parentId &&
        tag.creator_criteria_tag_id === criteriaId
    );

    if (index !== -1) {
      dispatch(removeCriteriaTag(index));
    } else {
      dispatch(
        addCriteriaTag({
          tag_heading_id: parentId,
          creator_criteria_tag_id: criteriaId,
        })
      );
    }
  };

  useEffect(() => {
    if (!isFetching) {
      setCreatorTags(data?.data || []);
    }
  }, [isFetching, data]);

  useEffect(() => {
    if (criteriaTagId !== null) {
      criteriaTagId?.forEach((x) => {
        console.log(x)
        x?.tag_heading?.creator_criteria_tag?.forEach((y) => {
          handleCreatorCriteriaTags(x?.tag_heading?.id, y?.id);
        });
      });
    }
  }, [criteriaTagId]);

  return (
    <div className="grid grid-cols-12 mt-4">
      {creatorTags.map((x) => (
        <div className="col-span-12 sm:col-span-6" key={x.id}>
          {x.status !== 0 && (
            <>
              <h6 className="font-semibold">{x.name}</h6>
              <div className="flex items-center gap-4">
                {x.tags.map((y) => (
                  <div className="flex items-center mb-4" key={y.id}>
                    <input
                      id={`checkbox-${x.id}-${y.id}`}
                      type="checkbox"
                      value=""
                      checked={selectedCreatorCriteriaTags.some(
                        (z) =>
                          z.tag_heading_id === x.id &&
                          z.creator_criteria_tag_id === y.id
                      )}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-transparent focus:ring-2"
                      onChange={() => handleCreatorCriteriaTags(x.id, y.id)}
                    />
                    <label
                      htmlFor={`checkbox-${x.id}-${y.id}`}
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      {y.tag}
                    </label>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default Criteria;
