import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../Common/Form/Input/Input";
import { brandWebsite, videoTypeName } from "../../../Utils/InputValidations";
import {
  useCreateBrandMutation,
  useGetBrandCategoriesQuery,
} from "../../../store/Services/API";
import {
  setShowToaster,
  setToasterMessage,
  setToasterType,
} from "../../../store/Slice/mainSlice";
import { useDispatch } from "react-redux";
import SelectDropdown from "../../Common/Form/SelectDropdown/SelectDropdown";
import countryList from "react-select-country-list";
import Button from "../../Common/Button";

function CreateBrand() {
  const methods = useForm();
  const [createBrand] = useCreateBrandMutation();
  const options = useMemo(() => countryList().getData(), []);
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();

  const { data, isFetching } = useGetBrandCategoriesQuery();

  const onSubmit = async (data) => {
    setLoader(true);
    const formData = new FormData();
    console.log(data)
    formData.append("name", data.name);
    formData.append("country", data.country);
    formData.append("brand_category_id", data.brand_category_id);
    formData.append("url", data.url);

    try {
      const response = await createBrand(formData);
      if (response.data.status) {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Success"));
        setLoader(false);
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Error"));
        setLoader(false);
      }
    } catch (error) {
      dispatch(setShowToaster(true));
      dispatch(
        setToasterMessage("Something went wrong. Please try again later.")
      );
      dispatch(setToasterType("Error"));
      setLoader(false);
    }
  };

  useEffect(() => {
    if (data) {
      setCategories(data?.data?.map((x) => ({ value: x.id, label: x.name })));
    }
  }, [data]);

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    document.documentElement.style.overflow = 'hidden'

    return () => {
        document.body.style.overflow = 'auto'
        document.documentElement.style.overflow = 'auto'
    }
}, [])

  return (
    <div className="w-full h-screen bg-black/50 fixed inset-0 z-50 flex flex-col justify-center items-center">
      <div className="max-w-xl bg-white rounded-lg border border-[#cccccc] p-5 w-full">
        <h5 className="font-semibold">Add your brand</h5>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 mt-6">
              <div className="col-span-12">
                <Input {...videoTypeName} />
              </div>
              <div className="col-span-12">
                <SelectDropdown
                  label="Brand Category"
                  required={true}
                  options={categories}
                  name="brand_category_id"
                />
              </div>
              <div className="col-span-12 mt-4">
                <Input {...brandWebsite} />
              </div>
              <div className="col-span-12">
                <SelectDropdown
                  label="Brand Country"
                  required={true}
                  options={options}
                  name="country"
                />
              </div>
              <div className="col-span-12">
                <div className="w-24 ml-auto">
                  <Button text="Add" loader={loader} />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default CreateBrand;
