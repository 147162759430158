import React from "react";
import { Link } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import MasterLayout from "../../Components/Common/Layout/MasterLayout";
import { useDispatch, useSelector } from "react-redux";
import { HiDuplicate } from "react-icons/hi";
import { MdOutlineModeEdit } from "react-icons/md";
import { setTaskDetail } from "../../store/Slice/mainSlice";

function Tasks() {
  const userBrands = useSelector((state) => state.mainSlice.userBrands);
  const selectedUserBrand = useSelector(
    (state) => state.mainSlice.selectedUserBrand
  );
  const dispatch = useDispatch();

  return (
    <MasterLayout>
      <div className="sm:p-5">
        <div className="shadow-lg bg-white overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 table-auto">
            <thead className="text-xs text-gray-700 uppercase bg-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Task
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Videos
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {userBrands[selectedUserBrand]?.tasks.length > 0 ? (
                userBrands[selectedUserBrand]?.tasks?.map((x) => (
                  <tr className="bg-white border-b bg-white" key={x.id}>
                    <td className="px-6 py-4 min-w-[300px]">
                      <Link
                        className="text-sm text-[#00beff] underline"
                        to={`/brands/campaigns/${x.id}/info/view`}
                      >
                        {x?.video_duration?.duration} {x?.video_type?.name}
                      </Link>
                      <div className="flex gap-4 mt-3">
                        <span className="">2023-06-07</span>
                        <Link
                          className="flex items-center gap-1 text-sm text-[#00beff] underline"
                          to={`/brands/campaigns/${x?.id}/info/edit`}
                        >
                          <MdOutlineModeEdit /> Edit Task
                        </Link>
                      </div>
                    </td>
                    <td className="px-6 py-4">{x?.status}</td>
                    <td className="px-6 py-4">{x.number_of_videos}</td>
                    <td className="px-6 py-4">
                      $
                      {parseInt(x?.number_of_videos) *
                        (parseInt(x?.video_duration?.price) +
                          parseInt(x?.video_type?.price) +
                          parseInt(x?.creator?.price) +
                          parseInt(x?.in_house_service?.price))}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="px-6 py-4 text-center" colSpan="7">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </MasterLayout>
  );
}

export default Tasks;
