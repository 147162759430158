import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Banner() {
  const words = ["Forest", "Building", "Tree", "Color"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000); // Change word every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [words.length]);
  return (
    <div className="flex flex-col md:gap-4 py-20 border-b">
      <h1 className="text-center text-3xl md:text-[50px] font-semibold">
        Start with your first
      </h1>
      <div className="flex justify-center items-center">
        <div className="relative h-[80px]">
          {words.map((word, index) => (
            <span
              key={index}
              className={`absolute -left-12 transition-opacity duration-1000 ease-in-out text-3xl md:text-[40px] text-[#817efd] 
              ${index === currentWordIndex ? "opacity-100" : "opacity-0"}`}
            >
              {word}
            </span>
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center gap-3">
        <Link
          to="/campaign/new"
          className="bg-[#817EFD] text-white border border-[#817EFD] px-6 py-2 rounded-full"
          aria-current="page"
        >
          Get a video
        </Link>
        <button className="bg-white text-[#817EFD] border border-[#817EFD] px-4 py-2 rounded-full">How does it work?</button>
      </div>
    </div>
  );
}

export default Banner;
