import React from 'react'

function Card({children, title}) {
  return (
    <div className="shadow-xl rounded-lg w-full p-5 bg-white">
        <h6 className="font-semibold">{title}</h6>
        {children}
    </div>
  )
}

export default Card