import React, { useEffect } from "react";

function ModalLayout({ children, classes }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 fixed top-[0px] bg-black sm:bg-black/80 md:top-0 lg:top-0 lg:top-0 2xl:top-0 sm:backdrop-blur-xl w-full h-dvh left-0 z-50">
      <div
        className={`outerContainer w-full absolute top-0 sm:relative rounded-t-3xl sm:max-w-2xl px-5 sm:p-8 py-5 pb-16 bg-white rounded-lg shadow-md sm:rounded-lg ${classes}`}
      >
        {children}
      </div>
    </div>
  );
}

export default ModalLayout;
