import React from "react";
import MasterLayout from "../../Components/Common/Layout/MasterLayout";
import { Link } from "react-router-dom";
import { TfiAngleRight } from "react-icons/tfi";

function Dashboard() {
  return (
    <MasterLayout>
      <div className="md:p-5">
        <div className="grid grid-cols-12 gap-4 mb-4">
          <div className="col-span-6 sm:col-span-3">
            <div className="bg-white shadow-lg py-5 px-6 h-full">
              <h5>Active Tasks</h5>
              <h4 className="text-[#817EFD] text-[28px]">0</h4>
              <Link
                to="/campaign/new"
                className="text-[#18b8ff] flex items-center gap-2"
              >
                New Task <TfiAngleRight />
              </Link>
            </div>
          </div>
          <div className="col-span-6 md:col-span-3">
            <div className="bg-white shadow-lg py-5 px-6 h-full">
              <h5>Videos</h5>
              <h4 className="text-[#817EFD] text-[28px]">0</h4>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-3">
            <div className="bg-white shadow-lg py-5 px-6 md:h-[532px]">
              <h5>Approve Creators</h5>
              <div className="w-full h-full flex flex-col gap-4 justify-center items-center">
                <h2 className="text-center font-medium text-[#817EFD] text-2xl md:text-[28px]">Create a task for creators to apply</h2>
                <Link to="/campaign/new" className="flex items-center bg-[#817EFD] text-white font-semibold rounded-full px-3 py-1 gap-2">Create a task <TfiAngleRight /></Link>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-3">
            <div className="bg-white shadow-lg py-5 px-6 gap-4 md:h-[532px]">
              <h5>Ship Products</h5>
              <div className="w-full h-full flex flex-col justify-center items-center">
                <h2 className="text-center font-medium text-[#817EFD] text-2xl md:text-[28px]">No products to ship</h2>
                <p className="text-black/60 text-center text-sm md:text-base">Something shows up here once you approve the creators you wanna work with ✌️</p>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-3">
            <div className="bg-white shadow-lg py-5 px-6 gap-4 md:h-[532px]">
              <h5>Approve Videos</h5>
              <div className="w-full h-full flex flex-col justify-center items-center">
                <h3 className="text-center font-medium text-[#817EFD] text-2xl md:text-[28px]">No videos to approve</h3>
                <p className="text-black/60 text-center text-sm md:text-base">Something shows up here once the creator uploads a video. Stay tuned 👀</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MasterLayout>
  );
}

export default Dashboard;
