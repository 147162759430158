import EmailVerify from "../Pages/Guest/EmailVerify";
import Home from "../Pages/Guest/Home";
import Login from "../Pages/Guest/Login";
import NewCampaign from "../Pages/Guest/Campaign";
import OTPVerification from "../Pages/Guest/OTPVerification";
import Register from "../Pages/Guest/Register";
import { Role, DecisionType } from "./UserTypes";
import ForgotPassword from "../Pages/Guest/ForgotPassword";

export const GuestRoutes = [
    {
        path: '/login',
        component: Login,
        title: 'Users',
        exact: true,
        acl: {
            [Role.ADMIN]: {
                type: DecisionType.REDIRECT,
                meta: '/admin/dashboard'
            },
            [Role.USER]: {
                type: DecisionType.REDIRECT,
                meta: '/brands/dashboard'
            },
            [Role.GUEST]: {
                type: DecisionType.ALLOW,
            },
        },
    },
    {
        path: '/register',
        component: Register,
        title: 'Users',
        exact: true,
        acl: {
            [Role.ADMIN]: {
                type: DecisionType.REDIRECT,
                meta: '/admin/dashboard'
            },
            [Role.USER]: {
                type: DecisionType.REDIRECT,
                meta: '/brands/dashboard'
            },
            [Role.GUEST]: {
                type: DecisionType.ALLOW,
            },
        },
    },
    {
        path: '/verify-email',
        component: EmailVerify,
        title: 'Users',
        exact: true,
        acl: {
            [Role.ADMIN]: {
                type: DecisionType.REDIRECT,
                meta: '/admin/dashboard'
            },
            [Role.USER]: {
                type: DecisionType.REDIRECT,
                meta: '/brands/dashboard'
            },
            [Role.GUEST]: {
                type: DecisionType.ALLOW,
            },
        },
    },
    {
        path: '/otp-verification',
        component: OTPVerification,
        title: 'Users',
        exact: true,
        acl: {
            [Role.ADMIN]: {
                type: DecisionType.REDIRECT,
                meta: '/admin/dashboard'
            },
            [Role.USER]: {
                type: DecisionType.REDIRECT,
                meta: '/brands/dashboard'
            },
            [Role.GUEST]: {
                type: DecisionType.ALLOW,
            },
        },
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        title: 'Users',
        exact: true,
        acl: {
            [Role.ADMIN]: {
                type: DecisionType.REDIRECT,
                meta: '/admin/dashboard'
            },
            [Role.USER]: {
                type: DecisionType.REDIRECT,
                meta: '/brands/dashboard'
            },
            [Role.GUEST]: {
                type: DecisionType.ALLOW,
            },
        },
    },
    {
        path: '/',
        component: Home,
        title: 'Users',
        exact: true,
        acl: {
            [Role.ADMIN]: {
                type: DecisionType.REDIRECT,
                meta: '/admin/dashboard'
            },
            [Role.USER]: {
                type: DecisionType.REDIRECT,
                meta: '/brands/dashboard'
            },
            [Role.GUEST]: {
                type: DecisionType.ALLOW,
            },
        },
    },
    {
        path: '/campaign/new',
        component: NewCampaign,
        title: 'Users',
        exact: true,
        acl: {
            [Role.ADMIN]: {
                type: DecisionType.ALLOW
            },
            [Role.USER]: {
                type: DecisionType.ALLOW
            },
            [Role.GUEST]: {
                type: DecisionType.ALLOW,
            },
        },
    },
]