import React, { useState } from "react";
import MasterLayout from "../../../Components/Common/Layout/MasterLayout";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setShowToaster,
  setToasterMessage,
  setToasterType,
} from "../../../store/Slice/mainSlice";
import { useVideoDurationEditMutation } from "../../../store/Services/API";
import Input from "../../../Components/Common/Form/Input/Input";
import {
    videoDurationName,
  videoDurationPrice,
} from "../../../Utils/InputValidations";
import Button from "../../../Components/Common/Button";

function VideoDurationAdminEdit() {
  const [loader, setLoader] = useState(false);
  const [editVideoDuration] = useVideoDurationEditMutation();
  const videoDurationToEdit = useSelector(
    (state) => state.mainSlice.videoDurationToEdit
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const methods = useForm();

  const onSubmit = async (data) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("duration", data.duration);
    formData.append("price", data.price);
    try {
      const response = await editVideoDuration({
        data: formData,
        id: videoDurationToEdit.id,
      });
      if (response.data.status) {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Success"));
        navigate(`/admin/video-duration`);
        setLoader(false);
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Error"));
        setLoader(false);
      }
    } catch (error) {
      dispatch(setShowToaster(true));
      dispatch(
        setToasterMessage("Something went wrong. Please try again later.")
      );
      dispatch(setToasterType("Error"));
      setLoader(false);
    }
  };

  return (
    <MasterLayout>
      <div className="bg-white p-4 shadow-lg">
        <h5 className="mb-10">Editing {videoDurationToEdit?.duration}</h5>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 sm:col-span-6">
                <Input {...videoDurationName} value={videoDurationToEdit?.duration} />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Input {...videoDurationPrice} value={videoDurationToEdit?.price} />
              </div>
              <div className="col-span-12">
                <div className="sm:w-24 ml-auto">
                  <Button text="Edit" loader={loader} />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </MasterLayout>
  );
}

export default VideoDurationAdminEdit;
