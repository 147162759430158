import React, { useEffect, useState } from "react";
import Card from "../MainCard";
import { useGetAspectRatioQuery } from "../../../../store/Services/API";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVideoAspectRatio } from "../../../../store/Slice/mainSlice";
import DataCard from "../DataCard";

function AspectRatio({ aspectRatioId = null }) {
  const [videoAspectRatio, setVideoAspectRatio] = useState([]);
  const { data, isFetching } = useGetAspectRatioQuery();
  const dispatch = useDispatch();
  const AspectRatio = useSelector(
    (state) => state.mainSlice.selectedVideoAspectRatio
  );

  useEffect(() => {
    if (!isFetching && data?.data) {
      setVideoAspectRatio(data?.data);
      if (aspectRatioId === null) {
        dispatch(
          setSelectedVideoAspectRatio(
            data?.data?.find((x) => x.status === 1).id
          )
        );
      }
    }
  }, [data, data?.data, dispatch, isFetching, AspectRatio]);

  useEffect(() => {
    if (!isFetching && data?.data) {
      setVideoAspectRatio(data?.data);
      if (aspectRatioId !== null) {
        dispatch(
          setSelectedVideoAspectRatio(
            data?.data?.find((x) => x.id === aspectRatioId).id
          )
        );
      }
    }
  }, [data, data?.data, dispatch, isFetching, aspectRatioId]);

  return (
    <Card title="Choose aspect ratio">
      <div className="grid grid-cols-12 gap-4 mt-4">
        {videoAspectRatio.length > 0 &&
          videoAspectRatio?.map((x) => (
            <>
              {x.status !== 0 && (
                <div
                  className="col-span-12 sm:col-span-3"
                  key={x.id}
                  onClick={() => dispatch(setSelectedVideoAspectRatio(x.id))}
                >
                  <DataCard
                    image={x.image}
                    title={x.heading}
                    price={`${x.price}`}
                    active={x.id === AspectRatio}
                    description={x.description}
                    ratio={x.ratio}
                  />
                </div>
              )}
            </>
          ))}
      </div>
    </Card>
  );
}

export default AspectRatio;
