import React, { useEffect, useState } from "react";
import { useGetActiveHomeCategoriesQuery } from "../../../store/Services/API";
import VideoPlayer from "../../Common/VideoPlayer";
import VideoThumbnail from "../../Common/Campaign/VideoThumbnail";
import ModalLayout from "../../Common/Layout/ModelLayout";
import { MdClose } from "react-icons/md";
import Masonry from "react-responsive-masonry";

function Categories() {
  const { data, isFetching } = useGetActiveHomeCategoriesQuery();
  const [selectedCategory, setSeletedCategory] = useState("");
  const [filterCategories, setFilterCategories] = useState(null);
  const [isPlayer, setIsPlayer] = useState({
    open: false,
    url: "",
  });

  const updatePlayerState = (newState) => {
    setIsPlayer((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const openPlayer = (url) => {
    updatePlayerState({ open: true, url });
  };

  const closePlayer = () => {
    updatePlayerState({ open: false, url: "" });
  };

  useEffect(() => {
    if (!isFetching) {
      setSeletedCategory(data?.categories[0]?.name);
    }
  }, [data?.categories, isFetching]);

  useEffect(() => {
    setFilterCategories(
      data?.categories?.filter((x) => x.name === selectedCategory)
    );
  }, [selectedCategory]);

  return (
    <div className="p-4">
      <div className="grid grid-cols-12 gap-4">
        <p className="col-span-12 sm:col-span-6 text-2xl">
          What UGC videos can you get?
        </p>
        <div className="col-span-12 sm:col-span-6 overflow-auto" id="navbar-default">
          <ul className="font-medium flex flex-row mb-8 p-4 md:p-0 sm:mt-4 rounded-lg md:flex-row space-x-4 rtl:space-x-reverse">
            {data?.categories?.map((x) => (
              <li
                className={`!list-none bg-[#f6f9fa] border py-1.5 px-4 rounded-full font-regular cursor-pointer hover:bg-[#f0e6ff] hover:border-[#817EFD] ${
                  selectedCategory === x.name &&
                  "!bg-[#f0e6ff] border-[#817EFD]"
                }`}
                onClick={() => setSeletedCategory(x.name)}
                key={x.id}
              >
                {x.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {filterCategories?.length > 0 &&
        filterCategories.map((x, index) => (
          <Masonry
            key={index}
            gutter="20px"
            columnsCountBreakPoints={{ 350: 5, 750: 2, 900: 3 }}
          >
            {x?.videos.map((y) => (
              <VideoThumbnail
                key={y.filename}
                url={y.filename}
                openPlayer={openPlayer}
              />
            ))}
          </Masonry>
        ))}
      {isPlayer.open && (
        <ModalLayout classes="!p-0">
          <div className="flex justify-end pt-3 pr-3">
            <MdClose
              fontSize="20px"
              className="cursor-pointer"
              onClick={() => closePlayer()}
            />
          </div>
          <VideoPlayer url={isPlayer.url} />
        </ModalLayout>
      )}
    </div>
  );
}

export default Categories;
