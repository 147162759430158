import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: JSON.parse(localStorage.getItem("biloUser")),
  showToaster: false,
  toasterMessage: "",
  toasterType: "",
  isLoggedIn: JSON.parse(localStorage.getItem("biloUser")) ? true : false,
  userEmail: "",
  videoDetailsEnabaled: true,
  paymentEnabled: false,
  videoBriefEnabled: false,
  selectedVideoTypeId: "",
  numberOfVideos: 1,
  selectedVideoDurationId: "",
  selectedVideoAspectRatio: "",
  selectedInHouseServicesId: "",
  selectedAuthenticPhotosId: "",
  selectedCreatorId: "",
  selectedCreatorCriteriaTags: [],
  videoTypeToEdit: {},
  videoDurationToEdit: {},
  videoAspectRatioToEdit: {},
  inHouseServiceToEdit: {},
  creatorsToEdit: {},
  creatorCriteriaToEdit: {},
  authenticPhotosToEdit: {},
  productVideoUrl: "",
  videoAdditionalDetails: "",
  videoScenes: [],
  videoMentions: [],
  videoContentLinks: [],
  userBrands: [],
  selectedUserBrand: null,
  taskDetail: null,
  sidebarEnabled: false,
};

export const mainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setShowToaster: (state, action) => {
      state.showToaster = action.payload;
    },
    setToasterMessage: (state, action) => {
      state.toasterMessage = action.payload;
    },
    setToasterType: (state, action) => {
      state.toasterType = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setVideoDetailsEnabled: (state, action) => {
      state.videoDetailsEnabaled = action.payload;
    },
    setPaymentEnabled: (state, action) => {
      state.paymentEnabled = action.payload;
    },
    setVideoBriefEnabled: (state, action) => {
      state.videoBriefEnabled = action.payload;
    },
    setSelectedVideoTypeId: (state, action) => {
      state.selectedVideoTypeId = action.payload;
    },
    setSelectedVideoDurationId: (state, action) => {
      state.selectedVideoDurationId = action.payload;
    },
    setSelectedVideoAspectRatio: (state, action) => {
      state.selectedVideoAspectRatio = action.payload;
    },
    setSelectedInHouseServicesId: (state, action) => {
      state.selectedInHouseServicesId = action.payload;
    },
    setSelectedAuthenticPhotosId: (state, action) => {
      state.selectedAuthenticPhotosId = action.payload;
    },
    setSelectedCreatorId: (state, action) => {
      state.selectedCreatorId = action.payload;
    },
    setNumberOfVideos: (state, action) => {
      state.numberOfVideos = action.payload;
    },
    addCriteriaTag: (state, action) => {
      state.selectedCreatorCriteriaTags.push(action.payload);
    },
    removeCriteriaTag: (state, action) => {
      state.selectedCreatorCriteriaTags.splice(action.payload, 1);
    },
    setVideoTypeToEdit: (state, action) => {
      state.videoTypeToEdit = action.payload;
    },
    setVideoDurationToEdit: (state, action) => {
      state.videoDurationToEdit = action.payload;
    },
    setVideoAspectRatioToEdit: (state, action) => {
      state.videoAspectRatioToEdit = action.payload;
    },
    setInHouseServiceToEdit: (state, action) => {
      state.inHouseServiceToEdit = action.payload;
    },
    setCreatorsToEdit: (state, action) => {
      state.creatorsToEdit = action.payload;
    },
    setCreatorCriteriaToEdit: (state, action) => {
      state.creatorCriteriaToEdit = action.payload;
    },
    setAuthenticPhotosToEdit: (state, action) => {
      state.authenticPhotosToEdit = action.payload;
    },
    setProductVideoUrl: (state, action) => {
      state.productVideoUrl = action.payload;
    },
    setVideoScenes: (state, action) => {
      state.videoScenes = action.payload;
    },
    setVideoMentions: (state, action) => {
      state.videoMentions = action.payload;
    },
    setVideoContentLinks: (state, action) => {
      state.videoContentLinks = action.payload;
    },
    setVideoAdditionalDetails: (state, action) => {
      state.videoAdditionalDetails = action.payload;
    },
    setUserBrands: (state, action) => {
      state.userBrands = action.payload;
    },
    setSelectedUserBrand: (state, action) => {
      state.selectedUserBrand = action.payload;
    },
    setTaskDetail: (state, action) => {
      state.taskDetail = action.payload;
    },
    setSidebarEnabled: (state, action) => {
      state.sidebarEnabled = action.payload;
    },
  },
});

export const {
  setUserDetails,
  setShowToaster,
  setToasterMessage,
  setToasterType,
  setIsLoggedIn,
  setUserEmail,
  setVideoDetailsEnabled,
  setPaymentEnabled,
  setVideoBriefEnabled,
  setSelectedVideoTypeId,
  setSelectedVideoDurationId,
  setSelectedVideoAspectRatio,
  setSelectedInHouseServicesId,
  setSelectedAuthenticPhotosId,
  setSelectedCreatorId,
  setNumberOfVideos,
  addCriteriaTag,
  removeCriteriaTag,
  setVideoTypeToEdit,
  setVideoDurationToEdit,
  setVideoAspectRatioToEdit,
  setInHouseServiceToEdit,
  setCreatorsToEdit,
  setCreatorCriteriaToEdit,
  setAuthenticPhotosToEdit,
  setProductVideoUrl,
  setVideoScenes,
  setVideoMentions,
  setVideoContentLinks,
  setVideoAdditionalDetails,
  setUserBrands,
  setSelectedUserBrand,
  setTaskDetail,
  setSidebarEnabled,
} = mainSlice.actions;

export default mainSlice.reducer;
