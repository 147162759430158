import React from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useDispatch } from "react-redux";
import { setProductVideoUrl } from "../../../../store/Slice/mainSlice";

function ProductVideo() {
  const dispatch = useDispatch();

  const productVideo = (e) => {
    const file = e.target.files[0];
    if (file) {
      dispatch(setProductVideoUrl(file));
    }
  };
  return (
    <div className="bg-white shadow-lg p-5 mt-8 rounded-lg">
      <h5 className="uppercase font-semibold text-base sm:text-[1.25rem]">Select the product or service you want to feature:</h5>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 sm:col-span-3">
          <div className="shadow-lg w-full h-[200px] rounded-lg relative flex flex-col justify-center items-center ">
            <div className="flex flex-col justify-center items-center">
              <IoMdAddCircleOutline fontSize="45px" color="#817EFD" />
              <p className="mt-4 font-medium">Add a new product</p>
            </div>
            <input
              type="file"
              className="absolute w-full h-full opacity-0 cursor-pointer"
              onChange={(e) => productVideo(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductVideo;
