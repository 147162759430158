import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import {
  setIsLoggedIn,
  setSelectedUserBrand,
  setSidebarEnabled,
  setUserBrands,
  setUserDetails,
} from "../../store/Slice/mainSlice";
import { useGetUserBrandsQuery } from "../../store/Services/API";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

function Navbar() {
  const userBrands = useSelector((state) => state.mainSlice.userBrands);
  const isLoggedIn = useSelector((state) => state.mainSlice.isLoggedIn);
  const userDetails = useSelector((state) => state.mainSlice.userDetails);
  const sidebarEnabled = useSelector((state) => state.mainSlice.sidebarEnabled);
  const selectedUserBrand = useSelector(
    (state) => state.mainSlice.selectedUserBrand
  );
  const [skipUserBrand, setSkipUserBrand] = useState(true);
  const { data, isFetching } = useGetUserBrandsQuery(null, {
    skip: skipUserBrand,
  });
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const dropdownRefTwo = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdownTwo = () => {
    setIsOpenTwo(!isOpenTwo);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const logout = () => {
    localStorage.clear();
    dispatch(setIsLoggedIn(false));
    dispatch(setUserDetails(null));
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn && userDetails?.role?.name === "user") {
      setSkipUserBrand(false);
    }
  }, [isLoggedIn, userDetails]);

  useEffect(() => {
    if (data) {
      dispatch(setUserBrands(data?.data));
      dispatch(setSelectedUserBrand(0));
    }
  }, [data]);

  return (
    <nav className="fixed w-full top-0 z-50 bg-white border shadow-lg">
      <div className="flex items-center justify-between mx-auto p-4">
        <div className="flex gap-4 basis-1/2">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            Expose
          </Link>
          {isLoggedIn && userDetails?.role?.name === "user" && (
            <>
              {userBrands?.length > 0 && (
                <div className="relative" ref={dropdownRef}>
                  <button
                    className="text-black/90 text-2xl font-medium flex gap-4 items-center"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={toggleDropdown}
                  >
                    {userBrands[selectedUserBrand].name}
                    <IoMdArrowDropdown fontSize="20px" />
                  </button>
                  <div
                    className={`w-[350px] max-h-[354px] shadow-xl border rounded-lg p-5 absolute bg-white ${
                      isOpen ? "block" : "hidden"
                    }`}
                  >
                    <div className="relative">
                      <input
                        type="search"
                        placeholder="Type a brand name"
                        className="bg-[#F9F9F9] w-full border border-[#cccccc] rounded-[5px] text-sm text-black h-9 px-2 focus-visible:outline-none"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                      <AiOutlineSearch className="absolute right-2 top-[10px]" />
                    </div>
                    <div className="">
                      <p className="text-[#717171] text-sm mb-3 mt-8">Brands</p>
                      <div className="">
                        <ul className="list-none pl-0">
                          {userBrands.map((x, i) => (
                            <li
                              className={`list-none h-9 flex flex-col justify-center px-5 ${
                                selectedUserBrand === i && "bg-[#817efd66]"
                              }`}
                              onClick={() => dispatch(setSelectedUserBrand(i))}
                            >
                              <Link>{x.name}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="flex gap-4 mt-4">
                        <Link
                          className="basis-1/2 border border-[#817EFD] text-[#817EFD] text-center rounded-full text-sm py-1"
                          to={"/brands-list"}
                        >
                          See all brand
                        </Link>
                        <Link
                          className="basis-1/2 bg-[#817EFD] text-white rounded-full text-center text-sm py-1"
                          to={"/brands-list"}
                        >
                          New brand
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="block w-auto basis-3/4" id="navbar-default">
          {!isLoggedIn ? (
            <ul className="font-medium flex p-4 p-0 rounded-lg flex-row space-x-4 sm:space-x-8 rtl:space-x-reverse mt-0 float-right">
              <li className="list-none">
                <Link
                  to="/campaign/new"
                  className="bg-white text-[#817EFD] border border-[#817EFD] px-2 sm:px-4 py-2 rounded-full text-sm md:text-base"
                  aria-current="page"
                >
                  Get a video
                </Link>
              </li>
              <li className="list-none">
                <Link
                  to="/login"
                  className="bg-[#817EFD] text-white px-3 sm:px-4 py-2 rounded-full text-sm md:text-base"
                  aria-current="page"
                >
                  Login
                </Link>
              </li>
            </ul>
          ) : (
            <div className="flex items-center float-right gap-4">
              <GiHamburgerMenu className="block sm:hidden" fontSize="20px" onClick={(e) => {e.preventDefault();dispatch(setSidebarEnabled(!sidebarEnabled))}} />
              <div className="relative" ref={dropdownRefTwo}>
                <button
                  className="text-black/90 text-base font-normal flex gap-4 items-center"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={toggleDropdownTwo}
                >
                  <span className="hidden sm:block">
                    {userDetails?.first_name}
                    {userDetails?.last_name}
                    <IoMdArrowDropdown fontSize="20px" />
                  </span>
                  <span className="block sm:hidden">
                    <FaUser />
                  </span>
                </button>
                <div
                  className={`shadow-xl border rounded-lg absolute bg-white w-full top-10 min-w-[180px] -left-40 sm:left-0 ${
                    isOpenTwo ? "block" : "hidden"
                  }`}
                >
                  <ul className="pl-0">
                    <li
                      className="list-none flex items-center gap-2 cursor-pointer hover:text-[white] hover:bg-[#817efd]/70 px-5 py-2"
                      onClick={() => logout()}
                    >
                      <MdLogout /> Logout
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
