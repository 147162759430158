import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Common/Navbar";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  setVideoBriefEnabled,
  setVideoDetailsEnabled,
} from "../../store/Slice/mainSlice";
import VideoDetails from "../../Components/Common/Campaign/VideoDetails/Index";
import BottomNavbar from "../../Components/Common/BottomNavbar";
import VideoBrief from "../../Components/Common/Campaign/VideoBrief/VideoBrief";
import Sidebar from "../../Components/Common/Sidebar";
import CreateBrand from "../../Components/User/Models/CreateBrand";

function NewCampaign() {
  const dispatch = useDispatch();
  const videoDetailsEnabaled = useSelector(
    (state) => state.mainSlice.videoDetailsEnabaled
  );
  const videoBriefEnabled = useSelector(
    (state) => state.mainSlice.videoBriefEnabled
  );
  const isLoggedIn = useSelector((state) => state.mainSlice.isLoggedIn);
  const userBrands = useSelector((state) => state.mainSlice.userBrands);
  const [videoPrice, setVideoPrice] = useState({
    videoType: 0,
    numberOfVideos: 1,
    videoDuration: 0,
    inHouseServices: 0,
    authenticPhotos: 0,
    creators: 0,
    subTotal: 0,
    total: 0,
  });

  return (
    <>
      <Navbar />
      <div className={`${isLoggedIn && "sm:w-calc-full-minus-16rem ml-auto"}`}>
        {isLoggedIn && <Sidebar />}
        <div className="flex justify-around mt-4 items-start mt-32 sm:mt-28">
          {videoBriefEnabled && (
            <div
              onClick={() => {
                dispatch(setVideoBriefEnabled(false));
                dispatch(setVideoDetailsEnabled(true));
              }}
            >
              <AiOutlineArrowLeft
                className="bg-[#817EFD] rounded-full p-1 cursor-pointer ml-2 -mt-1 text-white"
                fontSize="20px"
              />
            </div>
          )}
          <div className="w-full text-center">
            <div
              className={`h-2 bg-[#817EFD]/30 w-[95%] mx-auto rounded-full ${
                videoDetailsEnabaled && "!bg-[#817EFD]"
              }`}
            ></div>
            <p
              className={`text-sm mt-1 ${
                videoDetailsEnabaled && "!text-[#817EFD]"
              }`}
            >
              Video Details
            </p>
          </div>
          <div className="w-full text-center">
            <div
              className={`h-2 bg-[#817EFD]/30 w-[95%] mx-auto rounded-full ${
                videoBriefEnabled && "!bg-[#817EFD]"
              }`}
            ></div>
            <p
              className={`text-sm mt-1 ${
                videoBriefEnabled && "!text-[#817EFD]"
              }`}
            >
              Video Brief
            </p>
          </div>
        </div>
        {videoDetailsEnabaled && <VideoDetails setVideoPrice={setVideoPrice} />}
        {videoBriefEnabled && (
          <>
            <VideoBrief setVideoPrice={setVideoPrice} />

            {userBrands?.length === 0 && <CreateBrand />}
          </>
        )}
        <BottomNavbar videoPrice={videoPrice} />
      </div>
    </>
  );
}

export default NewCampaign;
