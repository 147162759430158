import React, { useEffect, useState } from "react";
import MasterLayout from "../../Components/Common/Layout/MasterLayout";
import { useDispatch, useSelector } from "react-redux";
import VideoThumbnail from "../../Components/Common/Campaign/VideoThumbnail";
import VideoPlayer from "../../Components/Common/VideoPlayer";
import ModalLayout from "../../Components/Common/Layout/ModelLayout";
import { MdClose, MdEdit } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import { useGetTaskDetailQuery } from "../../store/Services/API";
import { setTaskDetail } from "../../store/Slice/mainSlice";

function ViewTask() {
  const taskDetail = useSelector((state) => state.mainSlice.taskDetail);
  const location = useLocation();
  const { data } = useGetTaskDetailQuery(location.pathname.split("/")[3]);
  const dispatch = useDispatch();

  const [isPlayer, setIsPlayer] = useState({
    open: false,
    url: "",
  });

  const updatePlayerState = (newState) => {
    setIsPlayer((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const openPlayer = (url) => {
    updatePlayerState({ open: true, url });
  };

  const closePlayer = () => {
    updatePlayerState({ open: false, url: "" });
  };

  useEffect(() => {
    if (data) {
      dispatch(setTaskDetail(data?.data));
    }
  }, [data]);

  return (
    <MasterLayout>
      {taskDetail !== null ? (
        <>
          <div className="sm:p-5">
            <Link
              to={`/brands/campaigns/${taskDetail?.id}/info/edit`}
              className="font-medium w-fit px-4 py-2 bg-[#817efd] text-white rounded-full mb-4 flex items-center gap-2"
            >
              <MdEdit /> Edit Task
            </Link>
            <div className="bg-white max-w-4xl p-5">
              <h5 className="font-semibold">General Information</h5>
              <table className="w-full">
                <tbody>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">
                      Product
                    </th>
                    <td className="px-5 py-6 text-left">
                      <VideoThumbnail
                        url={`${taskDetail?.video?.filename}`}
                        openPlayer={openPlayer}
                      />
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">Name</th>
                    <td className="px-5 py-6 text-left">
                      {taskDetail?.video_duration?.duration}{" "}
                      {taskDetail?.video_type?.name}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">
                      Display Name
                    </th>
                    <td className="px-5 py-6 text-left">
                      {taskDetail?.video_duration?.duration}{" "}
                      {taskDetail?.video_type?.name}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">
                      Status
                    </th>
                    <td className="px-5 py-6 text-left">
                      {taskDetail?.status}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">
                      Task ID
                    </th>
                    <td className="px-5 py-6 text-left">{taskDetail?.id}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="bg-white max-w-4xl p-5 mt-10">
              <h5 className="font-semibold">Video Specifications</h5>
              <table className="w-full">
                <tbody>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">Order</th>
                    <td className="px-5 py-6 text-left">
                      {taskDetail?.number_of_videos || "N/A"}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">
                      Video Type
                    </th>
                    <td className="px-5 py-6 text-left">
                      {taskDetail?.video_type?.name || "N/A"}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">
                      Duration
                    </th>
                    <td className="px-5 py-6 text-left">
                      {taskDetail?.video_duration?.duration || "N/A"}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">
                      Aspect Ratio
                    </th>
                    <td className="px-5 py-6 text-left">
                      <div className="flex gap-2">
                        <img
                          className="w-7 h-7"
                          src={`${process.env.REACT_APP_ASSET_URL}${taskDetail?.aspect_ratio?.image}`}
                          alt=""
                        />{" "}
                        {taskDetail?.aspect_ratio?.ratio || "N/A"}
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">
                      Authentic Photos
                    </th>
                    <td className="px-5 py-6 text-left">
                      {taskDetail !== null &&
                        taskDetail?.authentic_photo !== null &&
                        taskDetail?.authentic_photo !== undefined && (
                          <>
                            {Object?.keys(taskDetail?.authentic_photo)
                              ?.length !== 0
                              ? "Included"
                              : "Not Included"}
                          </>
                        )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {taskDetail?.creator_criteria_tag?.length > 0 && (
              <div className="bg-white max-w-4xl p-5 mt-10">
                <h5 className="font-semibold">Creator Criteria</h5>
                <table className="w-full">
                  <tbody>
                    {taskDetail?.creator_criteria_tag?.map((x, i) => (
                      <tr className="border-b" key={i}>
                        <th className="px-5 py-6 text-left font-semibold">
                          {x?.tag_heading?.name}
                        </th>
                        <td className="px-5 py-6 text-left" key={i}>
                          {x?.tag_heading?.creator_criteria_tag?.map((y, i) => (
                            <span>
                              {y?.tag || "N/A"}
                              {x?.tag_heading?.creator_criteria_tag.length - 1 >
                                i && ","}{" "}
                            </span>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="bg-white max-w-4xl p-5 mt-10">
              <h5 className="font-semibold">Additional Details</h5>
              <table className="w-full">
                <tbody>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">
                      Scenes
                    </th>
                    <td className="px-5 py-6 text-left">
                      {taskDetail?.scenes?.map((x, i) => (
                        <p key={i}>
                          {i + 1}- {x?.name}
                        </p>
                      ))}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">
                      Mentions
                    </th>
                    <td className="px-5 py-6 text-left">
                      {taskDetail?.mentions?.map((x, i) => (
                        <p key={i}>
                          {i + 1}- {x?.name}
                        </p>
                      ))}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">
                      Content Examples
                    </th>
                    <td className="px-5 py-6 text-left">
                      {taskDetail?.content_examples?.map((x, i) => (
                        <p key={i}>
                          {i + 1}- {x?.name}
                        </p>
                      ))}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-5 py-6 text-left font-semibold">
                      Additional Details
                    </th>
                    <td className="px-5 py-6 text-left">
                      {taskDetail?.additional_details || "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {isPlayer.open && (
            <ModalLayout classes="!p-0">
              <div className="flex justify-end pt-3 pr-3">
                <MdClose
                  fontSize="20px"
                  className="cursor-pointer"
                  onClick={() => closePlayer()}
                />
              </div>
              <VideoPlayer url={isPlayer.url} />
            </ModalLayout>
          )}
        </>
      ) : (
        <div>
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#000"
            ariaLabel="three-circles-loading"
            wrapperStyle={{}}
            wrapperClass="flex flex-col items-center h-screen justify-center"
          />
        </div>
      )}
    </MasterLayout>
  );
}

export default ViewTask;
