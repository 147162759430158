import React, { useEffect, useState } from "react";
import Card from "../MainCard";
import { useDispatch, useSelector } from "react-redux";
import { setNumberOfVideos } from "../../../../store/Slice/mainSlice";
import { BiMinus } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";

function VideoQuantity({ setVideoPrice, NoOfVideos = null }) {
  const [increment, setIncrement] = useState(1);
  const numberOfVideos = useSelector((state) => state.mainSlice.numberOfVideos);
  const dispatch = useDispatch();

  const decrementHandler = () => {
    if (increment !== 1) {
      setIncrement(increment - 1);
      dispatch(setNumberOfVideos(increment - 1));
      setVideoPrice((prevState) => ({
        ...prevState,
        numberOfVideos: (increment - 1),
      }))
    }
  };

  useEffect(() => {
    if (NoOfVideos === null) {
      if (numberOfVideos) {
        setIncrement(numberOfVideos);
        setVideoPrice((prevState) => ({
          ...prevState,
          numberOfVideos: numberOfVideos,
        }));
      }
    }
  }, [numberOfVideos, setVideoPrice]);

  useEffect(() => {
    if (NoOfVideos !== null) {
      setIncrement(parseInt(NoOfVideos));
      setVideoPrice((prevState) => ({
        ...prevState,
        numberOfVideos: parseInt(NoOfVideos),
      }));
    }
  }, [NoOfVideos]);
  return (
    <Card title="Number of videos">
      <div className="flex gap-5 my-5">
        <button
          className="w-12 h-12 rounded-full border border-[#817EFD] relative border-2"
          onClick={() => decrementHandler()}
        >
          <BiMinus
            onClick={() => decrementHandler()}
            disabled={increment === 1}
            className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-[#817EFD]"
          />
        </button>
        <div className="text-center">
          <p className="text-2xl text-[#817EFD]">{increment} video</p>
          <p>by {increment} creator</p>
        </div>
        <button
          className="w-12 h-12 rounded-full border border-[#817EFD] relative border-2"
          onClick={() => {
            setIncrement(increment + 1);
            dispatch(setNumberOfVideos(increment + 1));
            setVideoPrice((prevState) => ({
              ...prevState,
              numberOfVideos: (increment + 1),
            }));
          }}
        >
          <AiOutlinePlus className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-[#817EFD]" />
        </button>
      </div>
    </Card>
  );
}

export default VideoQuantity;
