import React, { useEffect, useState } from "react";
import Card from "../MainCard";
import { useGetVideoDurationQuery } from "../../../../store/Services/API";
import DataCard from "../DataCard";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVideoDurationId } from "../../../../store/Slice/mainSlice";

function VideoDuration({ setVideoPrice, DurationId = null }) {
  const [videoDuration, setVideoDuration] = useState([]);
  const { data, isFetching } = useGetVideoDurationQuery();
  const dispatch = useDispatch();
  const videoDurationId = useSelector(
    (state) => state.mainSlice.selectedVideoDurationId
  );

  const handleVideoDuration = (id, price) => {
    dispatch(setSelectedVideoDurationId(id));
    setVideoPrice((prevState) => ({
      ...prevState,
      videoDuration: price,
    }));
  };

  useEffect(() => {
    if (!isFetching && data?.data) {
      setVideoDuration(data?.data);
      if (DurationId === null) {
        dispatch(setSelectedVideoDurationId(data?.data?.find((x) => x.status === 1).id));
        setVideoPrice((prevState) => ({
          ...prevState,
          videoDuration: data?.data[0]?.price,
        }));
      }
    }
  }, [data, data?.data, dispatch, isFetching, videoDurationId]);

  useEffect(() => {
    if (!isFetching && data?.data) {
      setVideoDuration(data?.data);
      if (DurationId !== null) {
        dispatch(setSelectedVideoDurationId(data?.data?.find((x) => x.id === DurationId).id));
        setVideoPrice((prevState) => ({
          ...prevState,
          videoDuration: data?.data?.find((x) => x.id === DurationId)?.price,
        }));
      }
    }
  }, [data, data?.data, dispatch, isFetching, DurationId]);

  return (
    <Card title="Video duration">
      <div className="grid grid-cols-12 gap-4 mt-4">
        {videoDuration.length > 0 &&
          videoDuration?.map((x) => (
            <>
              {x.status !== 0 && (
                <div
                  className="col-span-12 sm:col-span-3"
                  key={x.id}
                  onClick={() => handleVideoDuration(x.id, x.price)}
                >
                  <DataCard
                    title={x.duration}
                    price={`${x.price}`}
                    active={x.id === videoDurationId}
                  />
                </div>
              )}
            </>
          ))}
      </div>
    </Card>
  );
}

export default VideoDuration;
