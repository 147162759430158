import React from "react";
import VideoType from "./VideoType";
import VideoQuantity from "./VideoQuantity";
import VideoDuration from "./VideoDuration";
import AspectRatio from "./AspectRatio";
import InHouseServices from "./InHouseServices";
import AuthenticPhotos from "./AuthenticPhotos";
import CreatorCriteria from "./CreatorCriteria/Index";
import { useSelector } from "react-redux";

function VideoDetails({setVideoPrice}) {
  const isLoggedIn = useSelector(
    (state) => state.mainSlice.isLoggedIn
  );
  return (
    <div className={`p-4 flex flex-col gap-10 ${isLoggedIn ? 'sm:mx-10' : 'sm:mx-40'} pb-28`}>
      <VideoType setVideoPrice={setVideoPrice} />
      <VideoQuantity setVideoPrice={setVideoPrice} />
      <VideoDuration setVideoPrice={setVideoPrice} />
      <AspectRatio />
      <InHouseServices setVideoPrice={setVideoPrice} />
      <AuthenticPhotos setVideoPrice={setVideoPrice} />
      <CreatorCriteria setVideoPrice={setVideoPrice} />
    </div>
  );
}

export default VideoDetails;
