import TaskAdmin from "../Pages/Admin/AllTasks/TaskAdmin";
import AuthenticPhotos from "../Pages/Admin/AuthenticPhotos/AuthenticPhotos";
import AuthenticPhotosAdd from "../Pages/Admin/AuthenticPhotos/AuthenticPhotosAdd";
import AddBrandCategories from "../Pages/Admin/BrandCategories/AddBrandCategories";
import BrandCategories from "../Pages/Admin/BrandCategories/BrandCategories";
import AddCategory from "../Pages/Admin/Category/AddCategory";
import Category from "../Pages/Admin/Category/Category";
import UploadCategoryMedia from "../Pages/Admin/Category/UploadCategoryMedia";
import CreatorCriteria from "../Pages/Admin/CreatorCriteria/CreatorCriteria";
import CreatorCriteriaAdd from "../Pages/Admin/CreatorCriteria/CreatorCriteriaAdd";
import CreatorCriteriaEdit from "../Pages/Admin/CreatorCriteria/CreatorCriteriaEdit";
import Creators from "../Pages/Admin/Creators/Creators";
import CreatorsAdd from "../Pages/Admin/Creators/CreatorsAdd";
import CreatorsEdit from "../Pages/Admin/Creators/CreatorsEdit";
import Dashboard from "../Pages/Admin/Dashboard";
import InHouseServices from "../Pages/Admin/InHouseServices/InHouseServices";
import InHouseServicesAdd from "../Pages/Admin/InHouseServices/InHouseServicesAdd";
import InHouseServicesEdit from "../Pages/Admin/InHouseServices/InHouseServicesEdit";
import VideoAspectRatioAddAdmin from "../Pages/Admin/VideoAspectRatio/VideoAspectRatioAddAdmin";
import VideoAspectRatioAdmin from "../Pages/Admin/VideoAspectRatio/VideoAspectRatioAdmin";
import VideoAspectRatioEditAdmin from "../Pages/Admin/VideoAspectRatio/VideoAspectRatioEditAdmin";
import VideoDurationAdmin from "../Pages/Admin/VideoDurationAdmin/VideoDurationAdmin";
import VideoDurationAdminAdd from "../Pages/Admin/VideoDurationAdmin/VideoDurationAdminAdd";
import VideoDurationAdminEdit from "../Pages/Admin/VideoDurationAdmin/VideoDurationAdminEdit";
import VideoTypeAdmin from "../Pages/Admin/VideoTypeAdmin/VideoTypeAdmin";
import VideoTypeAdminAdd from "../Pages/Admin/VideoTypeAdmin/VideoTypeAdminAdd";
import VideoTypeAdminEdit from "../Pages/Admin/VideoTypeAdmin/VideoTypeAdminEdit";
import { Role, DecisionType } from "./UserTypes";

export const AdminRoutes = [
  {
    path: "/admin/dashboard",
    component: Dashboard,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/video-type",
    component: VideoTypeAdmin,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/video-type/add",
    component: VideoTypeAdminAdd,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/video-type/:id/edit",
    component: VideoTypeAdminEdit,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/video-duration",
    component: VideoDurationAdmin,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/video-duration/add",
    component: VideoDurationAdminAdd,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/video-duration/:id/edit",
    component: VideoDurationAdminEdit,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/video-aspect-ratio",
    component: VideoAspectRatioAdmin,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/video-aspect-ratio/add",
    component: VideoAspectRatioAddAdmin,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/video-aspect-ratio/:id/edit",
    component: VideoAspectRatioEditAdmin,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/inhouse-services",
    component: InHouseServices,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/inhouse-services/add",
    component: InHouseServicesAdd,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/inhouse-services/:id/edit",
    component: InHouseServicesEdit,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/creators",
    component: Creators,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/creators/add",
    component: CreatorsAdd,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/creators/:id/edit",
    component: CreatorsEdit,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/creator-criteria",
    component: CreatorCriteria,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/creator-criteria/add",
    component: CreatorCriteriaAdd,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/creator-criteria/:id/edit",
    component: CreatorCriteriaEdit,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/authentic-photos",
    component: AuthenticPhotos,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/authentic-photos/add",
    component: AuthenticPhotosAdd,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/categories",
    component: Category,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/category/add",
    component: AddCategory,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/add-category-media/:id",
    component: UploadCategoryMedia,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/brand-categories",
    component: BrandCategories,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/brand-categories/add",
    component: AddBrandCategories,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
  {
    path: "/admin/tasks",
    component: TaskAdmin,
    title: "Users",
    exact: true,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.USER]: {
        type: DecisionType.REDIRECT,
        meta: "/brands/dashboard",
      },
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
    },
  },
];
