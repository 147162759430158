import React, { useState } from "react";
import MasterLayout from "../../../Components/Common/Layout/MasterLayout";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../Components/Common/Form/Input/Input";
import { useAuthenticPhotosAddMutation } from "../../../store/Services/API";
import { useDispatch } from "react-redux";
import {
  setShowToaster,
  setToasterMessage,
  setToasterType,
} from "../../../store/Slice/mainSlice";
import { useNavigate } from "react-router-dom";
import {
  authenticPhotosMedia,
  videoTypePrice,
} from "../../../Utils/InputValidations";
import Button from "../../../Components/Common/Button";
import TextEditor from "../../../Components/Common/Form/TextEditor/TextEditor";

function AuthenticPhotosAdd() {
  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const methods = useForm();
  const [addAuthenticPhotos] = useAuthenticPhotosAddMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getDescription = (value) => {
    methods.setValue("description", value);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImage(files);
  };

  const onSubmit = async (data) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("price", data.price);
    formData.append("description", data.description);
    formData.append("media", selectedImage[0]);

    try {
      const response = await addAuthenticPhotos(formData);
      if (response.data.status) {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Success"));
        navigate(`/admin/authentic-photos`);
        setLoader(false);
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Error"));
        setLoader(false);
      }
    } catch (error) {
      dispatch(setShowToaster(true));
      dispatch(
        setToasterMessage("Something went wrong. Please try again later.")
      );
      dispatch(setToasterType("Error"));
      setLoader(false);
    }
  };
  return (
    <MasterLayout>
      <div className="bg-white shadow-lg p-4">
        <h5 className="mb-10 uppercase">Add authentic photos</h5>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 sm:col-span-6">
                <Input {...videoTypePrice} />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Input
                  {...authenticPhotosMedia}
                  handleImageChange={handleImageChange}
                />
              </div>
              <div className="col-span-12">
                <TextEditor getDescription={getDescription} />
              </div>
              <div className="col-span-12">
                <div className="sm:w-24 ml-auto">
                  <Button text="Add" loader={loader} />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </MasterLayout>
  );
}

export default AuthenticPhotosAdd;
