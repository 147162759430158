import React from 'react'
import Card from '../../MainCard'
import Creators from './Creators'
import Criteria from './Criteria'

function CreatorCriteria({setVideoPrice}) {
  return (
   <Card title="Creator Criteria">
        <Creators setVideoPrice={setVideoPrice} />
        <Criteria />
   </Card>
  )
}

export default CreatorCriteria