import React from "react";

function AuthLayout({ children, title }) {
  return (
    <div className="w-full h-screen bg-[#f7f9fb] py-12 px-16">
      <h1 className="text-4xl hidden sm:block">Expose</h1>
      <div className="absolute w-full md:w-[40%] top-[50%] left-[50%] py-8 sm:py-12 px-6 sm:px-16 bg-white shadow-lg translate-x-[-50%] translate-y-[-50%]">
        <h2 className="text-3xl mb-10 text-[#817EFD] font-semibold">{title}</h2>
        {children}
        </div>
    </div>
  );
}

export default AuthLayout;
