import React, { useState } from "react";
import MasterLayout from "../../../Components/Common/Layout/MasterLayout";
import { useGetAllTasksQuery } from "../../../store/Services/API";
import { ThreeCircles } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";

function TaskAdmin() {
  const [skip, setSkip] = useState(false);
  const [pageNo, setPageNo] = useState("");
  const { data, isFetching } = useGetAllTasksQuery(pageNo, { skip });

  const setPage = (page) => {
    const queryString = page.split("=")
    setPageNo(queryString[1]);
    setSkip(false);
  };

  return (
    <MasterLayout>
      {!isFetching ? (
        <div className="sm:p-5">
          <div className="bg-white shadow-lg p-5">
            <h5 className="font-semibold">All User Tasks</h5>
            <div className="overflow-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 table-auto">
                <thead className="text-xs text-gray-700 uppercase bg-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Task
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Videos
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.data?.length > 0 ? (
                    data?.data?.data?.map((x, i) => (
                      <tr key={i}>
                        <td className="px-6 py-4 min-w-[300px] sm:min-w-[100%]">
                          <Link
                            className="text-sm text-[#00beff] underline"
                            to={`/brands/campaigns/${x.id}/info/view`}
                          >
                            {x?.video_duration?.duration} {x?.video_type?.name}
                          </Link>
                          <div className="flex gap-4 mt-3">
                            <span className="">2023-06-07</span>
                            <Link
                              className="flex items-center gap-1 text-sm text-[#00beff] underline"
                              to={`/brands/campaigns/${x?.id}/info/edit`}
                            >
                              <MdOutlineEdit /> Edit Task
                            </Link>
                          </div>
                        </td>
                        <td className="px-6 py-4">{x?.status}</td>
                        <td className="px-6 py-4">{x.number_of_videos}</td>
                        <td className="px-6 py-4">
                          $
                          {parseInt(x?.number_of_videos) *
                            (parseInt(x?.video_duration?.price) +
                              parseInt(x?.video_type?.price) +
                              parseInt(x?.creator?.price) +
                              parseInt(x?.in_house_service?.price))}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="px-6 py-4 text-center" colSpan="7">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end">
              <ul className="list-none flex gap-4 mt-4">
                {data?.data?.links?.map((x, i) => (
                  <li className="list-none" key={i}>
                    <button
                      className={`text-sm border p-1 px-3 border-[#817efd]/70 text-[#817efd]/70 hover:text-white ${
                        x.url !== null && "hover:bg-[#817efd]/70"
                      } disabled:border-gray-300 disabled:text-gray-300 disabled:cursor-not-allowed ${x.label == data?.data?.current_page && 'bg-[#817efd]/70 text-white'}`}
                      dangerouslySetInnerHTML={{ __html: x.label }}
                      disabled={x.url === null}
                      onClick={() => setPage(x.url)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <ThreeCircles
          visible={true}
          height="60"
          width="60"
          color="#000"
          ariaLabel="three-circles-loading"
          wrapperStyle={{}}
          wrapperClass="flex flex-col items-center h-screen justify-center"
        />
      )}
    </MasterLayout>
  );
}

export default TaskAdmin;
