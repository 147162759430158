import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion";
import { BiErrorCircle } from "react-icons/bi";
import { findInputError } from "../../../../Utils/FindInputError";
import { isFormInvalid } from "../../../../Utils/IsFormInvalid";

const Input = ({
  label,
  type,
  id,
  placeholder,
  name,
  value,
  multiline,
  validation,
  disabled,
  handleImageChange,
  multiple,
  accept,
  dir,
  classes
}) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const inputError = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputError);
  const inputValue = watch(name);

  return (
    <div className={`${name !== "tags" ? "mb-4" : "mb-0"}`}>
      <div className="z-0 w-full group relative">
        <label className="text-base text-black mb-1" htmlFor={id}>{label}</label>
        {multiline ? (
          <Controller
            name={name}
            control={control}
            defaultValue={value} // Set the default value from the 'value' prop
            rules={validation}
            render={({ field, rest }) => (
              <div>
                <textarea
                  type={type}
                  id={id}
                  className={`pt-1 bg-[#F9F9F9] border border-[#CCCCCC] rounded-lg !shadow-none !outline-none w-full text-sm text-[#989898] h-12 pl-2 ${isInvalid && 'border-red-500'}`}
                  placeholder={placeholder}
                  name={name}
                  {...field}
                  {...rest}
                />
              </div>
            )}
          />
        ) : (
          <Controller
            name={name}
            control={control}
            defaultValue={value}
            rules={validation}
            render={({ field }) => (
              <div>
                <input
                  type={type}
                  dir={dir}
                  autoComplete="new-password"
                  id={id}
                  accept={accept}
                  placeholder={placeholder}
                  name={name}
                  disabled={disabled}
                  {...field}
                  multiple={multiple}
                  className={`bg-[#F9F9F9] border border-[#CCCCCC] rounded-lg !shadow-none !outline-none w-full text-sm text-[#989898] h-12 pl-2 ${isInvalid && 'border-red-500'} ${classes}`}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    if (handleImageChange) {
                      handleImageChange(e);
                    }
                  }}
                />
              </div>
            )}
          />
        )}
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {isInvalid && (
          <InputError
            message={inputError.error.message}
            key={inputError.error.message}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

const InputError = ({ message }) => {
  return (
    <motion.p {...framer_error} className="flex text-red-500 text-sm">
      <BiErrorCircle className="mt-1" />
      {message}
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};

export default Input;
