import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const API = createApi({
  reducerPath: "API",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const localToken = JSON.parse(localStorage.getItem("biloToken"));
      if (localToken !== null) {
        if (localToken) {
          headers.set("Authorization", `Bearer ${localToken}`);
        }
      }

      return headers;
    },
  }),
  tagTypes: [
    "user",
    "VideoType",
    "categories",
    "VideoDuration",
    "authenticPhotos",
    "inHouseServices",
    "aspectRatio",
    "Creator",
    "CreatorTags",
    "brand",
    "brandCategories",
  ],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "login",
        method: "post",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: "reset-password",
        method: "post",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    register: builder.mutation({
      query: (data) => ({
        url: "register",
        method: "post",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    sendOTP: builder.mutation({
      query: (data) => ({
        url: "send-otp",
        method: "post",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    verifyOtp: builder.mutation({
      query: (data) => ({
        url: "verify-otp",
        method: "post",
        body: data,
      }),
    }),
    getActiveHomeCategories: builder.query({
      query: () => ({
        url: `active-category-media`,
        method: "get",
      }),
      providesTags: ["categories"],
    }),
    getVideoType: builder.query({
      query: () => ({
        url: "video-type",
        method: "get",
      }),
      providesTags: ["VideoType"],
    }),
    getVideoDuration: builder.query({
      query: () => ({
        url: "video-duration",
        method: "get",
      }),
      providesTags: ["VideoDuration"],
    }),
    getAspectRatio: builder.query({
      query: () => ({
        url: `aspect-ratio`,
        method: "get",
      }),
      providesTags: ["aspectRatio"],
    }),
    getInHouseServices: builder.query({
      query: () => ({
        url: `in-house-service`,
        method: "get",
      }),
      providesTags: ["inHouseServices"],
    }),
    getAuthenticPhotos: builder.query({
      query: () => ({
        url: `authentic-photo`,
        method: "get",
      }),
      providesTags: ["authenticPhotos"],
    }),
    getCreators: builder.query({
      query: () => ({
        url: `creator`,
        method: "get",
      }),
      providesTags: ["Creator"],
    }),
    getCreatorTags: builder.query({
      query: () => ({
        url: `creator-criteria-tag`,
        method: "get",
      }),
      providesTags: ["CreatorTags"],
    }),
    getUserStats: builder.query({
      query: () => ({
        url: `user-stats`,
        method: "get",
      }),
    }),
    getUserList: builder.query({
      query: () => ({
        url: `user-list`,
        method: "get",
      }),
    }),
    videoTypeStatus: builder.mutation({
      query: (data) => ({
        url: `status-change/${data.id}`,
        method: "post",
        body: data.status,
      }),
      invalidatesTags: ["VideoType"],
    }),
    videoTypeEdit: builder.mutation({
      query: (data) => ({
        url: `video-type/${data.id}`,
        method: "post",
        body: data?.data,
      }),
      invalidatesTags: ["VideoType"],
    }),
    videoTypeAdd: builder.mutation({
      query: (data) => ({
        url: `video-type`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["VideoType"],
    }),
    videoDurationStatus: builder.mutation({
      query: (data) => ({
        url: `duration-status/${data.id}`,
        method: "post",
        body: data.status,
      }),
      invalidatesTags: ["VideoDuration"],
    }),
    videoDurationEdit: builder.mutation({
      query: (data) => ({
        url: `video-duration/${data.id}`,
        method: "put",
        body: data?.data,
      }),
      invalidatesTags: ["VideoDuration"],
    }),
    videoDurationAdd: builder.mutation({
      query: (data) => ({
        url: `video-duration`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["VideoDuration"],
    }),
    videoAspectRationStatus: builder.mutation({
      query: (data) => ({
        url: `status-change-aspect/${data.id}`,
        method: "post",
        body: data?.status,
      }),
      invalidatesTags: ["aspectRatio"],
    }),
    videoAspectRatioEdit: builder.mutation({
      query: (data) => ({
        url: `aspect-ratio/${data.id}`,
        method: "post",
        body: data?.data,
      }),
      invalidatesTags: ["aspectRatio"],
    }),
    videoAspectRatioAdd: builder.mutation({
      query: (data) => ({
        url: `aspect-ratio`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["aspectRatio"],
    }),
    inHouseServiceStatus: builder.mutation({
      query: (data) => ({
        url: `status-change-service/${data.id}`,
        method: "post",
        body: { status: data?.status },
      }),
      invalidatesTags: ["inHouseServices"],
    }),
    inHouseServiceEdit: builder.mutation({
      query: (data) => ({
        url: `update-in-house-service/${data.id}`,
        method: "post",
        body: data?.data,
      }),
      invalidatesTags: ["inHouseServices"],
    }),
    inHouseServiceAdd: builder.mutation({
      query: (data) => ({
        url: `in-house-service`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["inHouseServices"],
    }),
    creatorsStatus: builder.mutation({
      query: (data) => ({
        url: `status-change-creator/${data.id}`,
        method: "post",
        body: { status: data?.status },
      }),
      invalidatesTags: ["Creator"],
    }),
    creatorsEdit: builder.mutation({
      query: (data) => ({
        url: `update-creator/${data.id}`,
        method: "post",
        body: data?.data,
      }),
      invalidatesTags: ["Creator"],
    }),
    creatorsAdd: builder.mutation({
      query: (data) => ({
        url: `creator`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["Creator"],
    }),
    creatorCriteriaStatus: builder.mutation({
      query: (data) => ({
        url: `change-creator-tag-status/${data.id}`,
        method: "post",
        body: { status: data?.status },
      }),
      invalidatesTags: ["CreatorTags"],
    }),
    creatorsCriteriaEdit: builder.mutation({
      query: (data) => ({
        url: `creator-criteria-tag/${data.id}`,
        method: "post",
        body: data?.data,
      }),
      invalidatesTags: ["CreatorTags"],
    }),
    creatorsCriteriaAdd: builder.mutation({
      query: (data) => ({
        url: `creator-criteria-tag`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["CreatorTags"],
    }),
    authenticPhotosStatus: builder.mutation({
      query: (data) => ({
        url: `change-creator-tag-status/${data.id}`,
        method: "post",
        body: { status: data?.status },
      }),
      invalidatesTags: ["authenticPhotos"],
    }),
    authenticPhotosEdit: builder.mutation({
      query: (data) => ({
        url: `creator-criteria-tag/${data.id}`,
        method: "post",
        body: data?.data,
      }),
      invalidatesTags: ["authenticPhotos"],
    }),
    authenticPhotosAdd: builder.mutation({
      query: (data) => ({
        url: `authentic-photo`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["authenticPhotos"],
    }),
    getCategories: builder.query({
      query: () => ({
        url: `categories`,
        method: "get",
      }),
      providesTags: ["categories"],
    }),
    categoryStatus: builder.mutation({
      query: (data) => ({
        url: `change-category-status/${data.id}`,
        method: "post",
        body: { status: data?.status },
      }),
      invalidatesTags: ["categories"],
    }),
    addCategories: builder.mutation({
      query: (data) => ({
        url: `categories`,
        method: "post",
        body: data,
      }),
      providesTags: ["categories"],
    }),
    addCategoriesMedia: builder.mutation({
      query: (data) => ({
        url: `videos`,
        method: "post",
        body: data,
      }),
      providesTags: ["categories"],
    }),
    createTasks: builder.mutation({
      query: (data) => ({
        url: "task",
        method: "post",
        body: data,
      }),
      invalidatesTags: ["brand"],
    }),
    getUserBrands: builder.query({
      query: () => ({
        url: `user-brands`,
        method: "get",
      }),
      providesTags: ["brand"],
    }),
    createBrand: builder.mutation({
      query: (data) => ({
        url: "brand",
        method: "post",
        body: data,
      }),
      invalidatesTags: ["brand"],
    }),
    getBrandCategories: builder.query({
      query: () => ({
        url: `brand-categories`,
        method: "get",
      }),
      providesTags: ["brandCategories"],
    }),
    createBrandCategories: builder.mutation({
      query: (data) => ({
        url: "brand-categories",
        method: "post",
        body: data,
      }),
      invalidatesTags: ["brandCategories"],
    }),
    getTaskDetail: builder.query({
      query: (id) => ({
        url: `task/${id}`,
        method: "get",
      }),
      providesTags: ["brand"],
    }),
    updateTask: builder.mutation({
      query: (data) => ({
        url: `update-task/${data.id}`,
        method: "post",
        body: data.data,
      }),
      invalidatesTags: ["brand"],
    }),
    editBrand: builder.mutation({
      query: (data) => ({
        url: `brand-update/${data.id}`,
        method: "post",
        body: data.data,
      }),
      invalidatesTags: ["brand"],
    }),
    getAllTasks: builder.query({
      query: (query) => ({
        url: `tasks?page=${query}`,
        method: "get",
      }),
      providesTags: ["tasks"],
    }),
  }),
});

export const {
  useLoginMutation,
  useResetPasswordMutation,
  useRegisterMutation,
  useSendOTPMutation,
  useVerifyOtpMutation,
  useGetActiveHomeCategoriesQuery,
  useGetVideoTypeQuery,
  useGetVideoDurationQuery,
  useGetAspectRatioQuery,
  useGetInHouseServicesQuery,
  useGetAuthenticPhotosQuery,
  useGetCreatorsQuery,
  useGetCreatorTagsQuery,
  useGetUserStatsQuery,
  useGetUserListQuery,
  useVideoTypeStatusMutation,
  useVideoTypeEditMutation,
  useVideoTypeAddMutation,
  useVideoDurationStatusMutation,
  useVideoDurationAddMutation,
  useVideoDurationEditMutation,
  useVideoAspectRatioAddMutation,
  useVideoAspectRatioEditMutation,
  useVideoAspectRationStatusMutation,
  useInHouseServiceStatusMutation,
  useInHouseServiceEditMutation,
  useInHouseServiceAddMutation,
  useCreatorsStatusMutation,
  useCreatorsAddMutation,
  useCreatorsEditMutation,
  useCreatorCriteriaStatusMutation,
  useCreatorsCriteriaAddMutation,
  useCreatorsCriteriaEditMutation,
  useAuthenticPhotosStatusMutation,
  useAuthenticPhotosEditMutation,
  useAuthenticPhotosAddMutation,
  useGetCategoriesQuery,
  useCategoryStatusMutation,
  useAddCategoriesMutation,
  useAddCategoriesMediaMutation,
  useCreateTasksMutation,
  useGetUserBrandsQuery,
  useCreateBrandMutation,
  useGetBrandCategoriesQuery,
  useCreateBrandCategoriesMutation,
  useGetTaskDetailQuery,
  useUpdateTaskMutation,
  useEditBrandMutation,
  useGetAllTasksQuery,
} = API;
