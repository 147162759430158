import React, { useState } from "react";
import AuthLayout from "../../Components/Common/Layout/AuthScreensLayout";
import { useDispatch } from "react-redux";
import {
  setShowToaster,
  setToasterMessage,
  setToasterType,
  setUserEmail,
} from "../../store/Slice/mainSlice";
import { useForm, FormProvider } from "react-hook-form";
import { Email } from "../../Utils/InputValidations";
import { useLocation, useNavigate } from "react-router-dom";
import { useSendOTPMutation } from "../../store/Services/API";
import Input from "../../Components/Common/Form/Input/Input";
import Button from "../../Components/Common/Button";

function EmailVerify() {
  const methods = useForm();
  const [verifyEmail] = useSendOTPMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const location = useLocation()

  let { url } = location.state || ''

  const onSubmit = async (data) => {
    setLoader(true);
    try {
      const response = await verifyEmail(data);
      if (response?.data?.status) {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Success"));
        setLoader(false);
        navigate('/otp-verification', { state: { url: `${url}` } })
        dispatch(setUserEmail(data.email))
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterMessage("Error"));
        setLoader(false);
      }
    } catch (error) {
      dispatch(setShowToaster(true));
      dispatch(
        setToasterMessage("Something went wrong. Please try again later.")
      );
      dispatch(setToasterType("Error"));
      setLoader(false);
    }
  };

  return (
    <AuthLayout title="Email Verification">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <Input {...Email} />
          </div>
          <Button loader={loader} text='Verify' />
        </form>
      </FormProvider>
    </AuthLayout>
  );
}

export default EmailVerify;
