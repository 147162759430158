import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Sidebar from "../../Sidebar";
import ProductVideo from "./ProductVideo";
import Scenario from "./Scenario";

function VideoBrief() {
  const isLogin = useSelector((state) => state.mainSlice.isLoggedIn);
  if (isLogin) {
    return (
      <div className="p-5">
        <h5 className="font-bold mt-3">What's Next?</h5>
        <p>
          Now, let's fill in the rest of the details so when the creators will
          pick up your task, they can proceed smoothly.
        </p>
        <ProductVideo />
        <Scenario />
      </div>
    );
  } else {
    return (
      <Navigate to={"/login"} state={{ from: { pathname: "/campaign/new" } }} />
    );
  }
}

export default VideoBrief;
