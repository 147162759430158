import React from 'react'
import Navbar from '../../Components/Common/Navbar'
import Banner from '../../Components/Guest/Home/Banner'
import Categories from '../../Components/Guest/Home/Categories'

function Home() {
  return (
    <>
        <Navbar />
        <Banner />
        <Categories />
    </>
  )
}

export default Home