import React, { useState } from "react";
import AuthLayout from "../../Components/Common/Layout/AuthScreensLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowToaster,
  setToasterMessage,
  setToasterType,
  setUserEmail,
} from "../../store/Slice/mainSlice";
import { useForm, FormProvider } from "react-hook-form";
import { Email } from "../../Utils/InputValidations";
import { useLocation, useNavigate } from "react-router-dom";
import { useVerifyOtpMutation } from "../../store/Services/API";
import Input from "../../Components/Common/Form/Input/Input";
import Button from "../../Components/Common/Button";
import OtpInput from "react-otp-input";

function OTPVerification() {
  const methods = useForm();
  const [verifyOTP] = useVerifyOtpMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const userEmail = useSelector((state) => state.mainSlice.userEmail)

  let { url } = location.state || "";

  const onSubmit = async (data) => {
    setLoader(true);
    if (otp.length < 4) {
      dispatch(setShowToaster(true));
      dispatch(setToasterMessage("Invalid OTP code"));
      dispatch(setToasterType("Error"));
      setLoader(false);
    } else {
      const formData = new FormData();
      formData.append("otp", otp);
      formData.append("email", userEmail);

      try {
        const response = await verifyOTP(formData);
        if (response.data.status) {
          dispatch(setShowToaster(true));
          dispatch(setToasterMessage(response.data.message));
          dispatch(setToasterType("Success"));
          setLoader(false);
          navigate(`/${url}`);
        } else {
          dispatch(setShowToaster(true));
          dispatch(setToasterMessage(response.data.message));
          dispatch(setToasterType("Error"));
          setLoader(false);
        }
      } catch (error) {
        dispatch(setShowToaster(true));
        dispatch(
          setToasterMessage("Something went wrong. Please try again later.")
        );
        dispatch(setToasterType("Error"));
        setLoader(false);
      }
    }
  };
  return (
    <AuthLayout title="OTP Verification">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <OtpInput
              containerStyle="otp_input"
              value={otp}
              onChange={setOtp}
              numInputs={5}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <Button loader={loader} text="Verify" />
        </form>
      </FormProvider>
    </AuthLayout>
  );
}

export default OTPVerification;
