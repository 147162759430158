import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { IoIosAdd } from "react-icons/io";
import {
  setVideoAdditionalDetails,
  setVideoScenes,
  setVideoMentions,
  setVideoContentLinks,
} from "../../../../store/Slice/mainSlice";
import { useDispatch } from "react-redux";

function Scenario({
  addedScenes = null,
  addedMentions = null,
  addedContentLinks = null,
  addedadditionalDetails = null,
}) {
  const [scenario, setScenario] = useState([
    {
      name: "",
    },
  ]);

  const [mentions, setMentions] = useState([
    {
      name: "",
    },
  ]);

  const [contentLink, setContentLink] = useState([
    {
      name: "",
    },
  ]);

  const [textArea, setTextArea] = useState("");

  const dispatch = useDispatch();

  const maxWords = 1024;

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const wordCount = inputValue.trim().split(/\s+/).length;

    if (wordCount <= maxWords) {
      setTextArea(inputValue);
      dispatch(setVideoAdditionalDetails(inputValue));
    } else {
      setTextArea(inputValue.slice(0, maxWords));
      dispatch(setVideoAdditionalDetails(inputValue.slice(0, maxWords)));
    }
  };

  const addScenarios = () => {
    setScenario([...scenario, { name: "" }]);
  };

  const addContentLink = () => {
    setContentLink([...contentLink, { name: "" }]);
  };

  const addMentions = () => {
    setMentions([...mentions, { name: "" }]);
  };

  const handleScenarios = (i, e) => {
    let newFormValues = [...scenario];
    newFormValues[i] = { ...newFormValues[i], [e.target.name]: e.target.value };
    setScenario(newFormValues);
    dispatch(setVideoScenes(newFormValues));
  };

  const handleContentLink = (i, e) => {
    let newFormValues = [...contentLink];
    newFormValues[i] = { ...newFormValues[i], [e.target.name]: e.target.value };
    setContentLink(newFormValues);
    dispatch(setVideoContentLinks(newFormValues));
  };

  const handleMentions = (i, e) => {
    let newFormValues = [...mentions];
    newFormValues[i] = { ...newFormValues[i], [e.target.name]: e.target.value };
    setMentions(newFormValues);
    dispatch(setVideoMentions(newFormValues));
  };

  const removeScenarios = (i) => {
    if (scenario.length > 1) {
      let newFormValues = [...scenario];
      newFormValues.splice(i, 1);
      setScenario(newFormValues);
      dispatch(setVideoScenes(newFormValues));
    }
  };

  const removeContentLink = (i) => {
    if (contentLink.length > 1) {
      let newFormValues = [...contentLink];
      newFormValues.splice(i, 1);
      setContentLink(newFormValues);
      dispatch(setVideoContentLinks(newFormValues));
    }
  };

  const removeMentions = (i) => {
    if (mentions.length > 1) {
      let newFormValues = [...mentions];
      newFormValues.splice(i, 1);
      setMentions(newFormValues);
      dispatch(setVideoMentions(newFormValues));
    }
  };

  useEffect(() => {
    if (addedMentions !== null) {
      const newMentions = addedMentions.map((x) => ({ name: x.name }));
      setMentions([...newMentions]);
      dispatch(setVideoMentions([...newMentions]));
    }
    if (addedScenes !== null) {
      const newScenes = addedScenes.map((x) => ({ name: x.name }));
      setScenario([...newScenes]);
      dispatch(setVideoScenes([...newScenes]));
    }
    if (addedContentLinks !== null) {
      const newContentLinks = addedContentLinks.map((x) => ({ name: x.name }));
      setContentLink([...newContentLinks]);
      dispatch(setVideoContentLinks([...newContentLinks]));
    }
    if (addedadditionalDetails !== null) {
      setTextArea(addedadditionalDetails);
      dispatch(setVideoAdditionalDetails(addedadditionalDetails));
    }
  }, [addedMentions, addedScenes, addedContentLinks, addedadditionalDetails]);

  useEffect(() => {
    console.log(mentions);
  }, []);

  useEffect(() => {
    console.log(mentions);
  }, [mentions]);

  return (
    <div className="bg-white rounded-lg shadow-lg p-5 mt-4 mb-20">
      <h5 className="uppercase font-semibold">Scenario</h5>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <h5 className="font-semibbold">Scenes</h5>
          <p className="font-medium">
            Scenes describes what can be sceen in a video
          </p>
        </div>
        {scenario.map((x, i) => (
          <>
            <div className="col-span-10 sm:col-span-11">
              <div>
                <input
                  type="text"
                  className="w-full py-[.375rem] px-[.75rem] text-sm placeholder:text-sm text-[#989898] placeholder:text-[#989898] bg-[#f9f9f9] border border-[#cccccc] rounded-lg focus-visible:outline-none"
                  value={x.name}
                  name="name"
                  placeholder="Scenes describes what can be sceen in a video"
                  id={`exampleInputEmail${i}`}
                  aria-describedby={`exampleInputEmail${i}`}
                  onChange={(e) => handleScenarios(i, e)}
                />
              </div>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <div>
                <AiOutlineDelete
                  className="bg-[#ff0000]/30 p-[7px] rounded-full cursor-pointer"
                  fontSize="35px"
                  color="red"
                  onClick={() => removeScenarios(i)}
                />
              </div>
            </div>
          </>
        ))}
        <p
          className="flex items-center text-sm text-[#817EFD] col-span-12"
          onClick={() => addScenarios()}
        >
          {" "}
          <IoIosAdd /> Add scene
        </p>
        <div className="col-span-12 mt-4">
          <h5 className="font-semibold">Mentions</h5>
          <p className="font-medium">
            Anything the creator should mention or highlight.
          </p>
        </div>
        {mentions.map((x, i) => (
          <>
            <div className="col-span-10 sm:col-span-11">
              <div>
                <input
                  type="text"
                  className="w-full py-[.375rem] px-[.75rem] text-sm placeholder:text-sm text-[#989898] placeholder:text-[#989898] bg-[#f9f9f9] border border-[#cccccc] rounded-lg focus-visible:outline-none"
                  value={x.name}
                  name="name"
                  placeholder="Anything the creator should mention or highlight."
                  id={`exampleInputEmail${i}`}
                  aria-describedby={`exampleInputEmail${i}`}
                  onChange={(e) => handleMentions(i, e)}
                />
              </div>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <div className="">
                <AiOutlineDelete
                  className="bg-[#ff0000]/30 p-[7px] rounded-full cursor-pointer"
                  fontSize="35px"
                  color="red"
                  onClick={() => removeMentions(i)}
                />
              </div>
            </div>
          </>
        ))}
        <p
          className="flex items-center text-sm text-[#817EFD] col-span-12"
          onClick={() => addMentions()}
        >
          {" "}
          <IoIosAdd /> Add mention
        </p>
        <div className="col-span-12 mt-4">
          <h5 className="font-semibold">Additonal details</h5>
          <p className="font-medium">
            Anything that would help creator deliver a perfect video
          </p>
          <div>
            <textarea
              className="w-full py-[.375rem] px-[.75rem] text-sm placeholder:text-sm text-[#989898] placeholder:text-[#989898] bg-[#f9f9f9] border border-[#cccccc] rounded-lg focus-visible:outline-none"
              value={textArea}
              onChange={handleChange}
              placeholder="Anything that would help creator deliver a perfect video"
              id="floatingTextarea2"
              style={{ height: "150px" }}
              onKeyPress={(event) => {
                if (
                  event.target.value.length >= maxWords &&
                  event.key !== "Backspace"
                ) {
                  event.preventDefault();
                }
              }}
            ></textarea>
            <p className="w-fit ml-auto text-[#989898] text-xs">
              {textArea.length}/1024
            </p>
          </div>
        </div>
        <div className="col-span-12 mt-4">
          <h5 className="font-semibold">Content examples</h5>
          <p className="font-medium">
            Please provide links to the content you like.
          </p>
        </div>
        {contentLink.map((x, i) => (
          <>
            <div className="col-span-10 sm:col-span-11">
              <div>
                <input
                  type="text"
                  className="w-full py-[.375rem] px-[.75rem] text-sm placeholder:text-sm text-[#989898] placeholder:text-[#989898] bg-[#f9f9f9] border border-[#cccccc] rounded-lg focus-visible:outline-none"
                  value={x.name}
                  name="name"
                  placeholder="Please provide link to the content you like."
                  id={`exampleInputEmail${i}`}
                  aria-describedby={`exampleInputEmail${i}`}
                  onChange={(e) => handleContentLink(i, e)}
                />
              </div>
            </div>
            <div className="col-span-2 sm:col-md-1 col-2">
              <div className="">
                <AiOutlineDelete
                  className="bg-[#ff0000]/30 p-[7px] rounded-full cursor-pointer"
                  fontSize="35px"
                  color="red"
                  onClick={() => removeContentLink(i)}
                />
              </div>
            </div>
          </>
        ))}
        <p
          className="flex items-center text-sm text-[#817EFD] col-span-12"
          onClick={() => addContentLink()}
        >
          {" "}
          <IoIosAdd /> Add link
        </p>
      </div>
    </div>
  );
}

export default Scenario;
