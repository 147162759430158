import React, { useEffect, useState } from "react";
import { useGetCreatorsQuery } from "../../../../../store/Services/API";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCreatorId } from "../../../../../store/Slice/mainSlice";
import DataCard from "../../DataCard";

function Creators({ setVideoPrice, creatorsId = null }) {
  const { data, isFetching } = useGetCreatorsQuery();
  const [creators, setCreators] = useState([]);
  const dispatch = useDispatch();
  const creatorId = useSelector((state) => state.mainSlice.selectedCreatorId);

  const handleCreators = (id, price) => {
    dispatch(setSelectedCreatorId(id));
    setVideoPrice((prevState) => ({
      ...prevState,
      creators: price,
    }));
  };

  useEffect(() => {
    if (data?.data) {
      setCreators(data?.data);
      if (creatorsId === null) {
        dispatch(
          setSelectedCreatorId(data?.data?.find((x) => x.status === 1).id)
        );
        setVideoPrice((prevState) => ({
          ...prevState,
          creators: data?.data[0]?.price,
        }));
      }
    }
  }, [data, data?.data, dispatch, isFetching, creatorId]);

  useEffect(() => {
    if (data?.data) {
      setCreators(data?.data);
      if (creatorsId !== null) {
        dispatch(
          setSelectedCreatorId(data?.data?.find((x) => x.id === creatorsId).id)
        );
        setVideoPrice((prevState) => ({
          ...prevState,
          creators: data?.data?.find((x) => x.id === creatorsId)?.price,
        }));
      }
    }
  }, [data, data?.data, dispatch, isFetching, creatorsId]);

  return (
    <div className="grid grid-cols-12 gap-4 mt-4">
      {creators?.map((x) => (
        <>
          {x.status !== 0 && (
            <div
              className="col-span-12 sm:col-span-4"
              key={x.id}
              onClick={() => handleCreators(x.id, x.price)}
            >
              <DataCard
                title={x.name}
                price={x.price}
                description={x.description}
                active={x.id === creatorId}
              />
            </div>
          )}
        </>
      ))}
    </div>
  );
}

export default Creators;
