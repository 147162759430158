import React, { useState } from "react";
import MasterLayout from "../../Components/Common/Layout/MasterLayout";
import {
  useGetUserListQuery,
  useGetUserStatsQuery,
} from "../../store/Services/API";
import { FaUsers } from "react-icons/fa";

function Dashboard() {
  const { data: userStats, isFetching } = useGetUserStatsQuery();
  const { data: userList, isFetching: fetchingUserList } =
    useGetUserListQuery();
  const [userType, setUserType] = useState("total_users");
  return (
    <MasterLayout>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 sm:col-span-4">
          <div
            className={`shadow-lg bg-white px-5 py-10 flex gap-5 items-start cursor-pointer ${
              userType === "total_users" && "border border-[#817efd] border-2"
            }`}
            onClick={() => setUserType("total_users")}
          >
            <FaUsers fontSize="25px" />
            <div>
              <h5 className="mb-0">Total Users</h5>
              <h4>{userStats?.total_users}</h4>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-4">
          <div
            className={`shadow-lg bg-white px-5 py-10 flex gap-5 items-start cursor-pointer ${
              userType === "users_with_tasks" &&
              "border border-[#817efd] border-2"
            }`}
            onClick={() => setUserType("users_with_tasks")}
          >
            <FaUsers fontSize="25px" />
            <div>
              <h5 className="mb-0">Users with Tasks</h5>
              <h4>{userStats?.users_with_tasks}</h4>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-4">
          <div
            className={`shadow-lg bg-white px-5 py-10 flex gap-5 items-start cursor-pointer ${
              userType === "users_without_tasks" &&
              "border border-[#817efd] border-2"
            }`}
            onClick={() => setUserType("users_without_tasks")}
          >
            <FaUsers fontSize="25px" />
            <div>
              <h5 className="mb-0">Users without Tasks</h5>
              <h4>{userStats?.users_without_tasks}</h4>
            </div>
          </div>
        </div>
        <div className="col-span-12 overflow-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 shadow-lg">
            <thead className="text-xs text-gray-700 uppercase bg-white">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Phone
                </th>
                <th scope="col" className="px-6 py-3">
                  Gender
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Task Created
                </th>
              </tr>
            </thead>
            <tbody>
              {userList && userList[userType]?.length > 0 ? (
                userList[userType]?.map((x) => (
                  <tr className="bg-white border-b bg-white">
                    <td className="px-6 py-4">
                      {x.first_name} {x.last_name}
                    </td>
                    <td className="px-6 py-4">{x?.email || "N/A"}</td>
                    <td className="px-6 py-4">{x?.phone_number || "N/A"}</td>
                    <td className="px-6 py-4">{x?.gender || "N/A"}</td>
                    <td className="px-6 py-4">{x?.tasks.length}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="px-6 py-4 text-center" colSpan='5'>No users available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </MasterLayout>
  );
}

export default Dashboard;
