export const Email = {
  label: "Email Address",
  type: "text",
  name: "email",
  placeholder: "Enter Email",
  validation: {
    required: {
      value: true,
      message: "Email is required",
    },
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      message: "Enter a valid email address",
    },
  },
};

export const Password = {
  label: "Password",
  type: "password",
  name: "password",
  placeholder: "Enter Password",
  validation: {
    required: {
      value: true,
      message: "Password is required",
    },
  },
};

export const firstName = {
  label: "First Name",
  type: "text",
  name: "first_name",
  placeholder: "Enter first name",
  validation: {
    required: {
      value: true,
      message: "First name is required",
    },
    pattern: {
      value: /^[A-Za-z]+$/i,
      message: "First name can only contain alphabetic characters",
    },
  },
};

export const lastName = {
  label: "Last Name",
  type: "text",
  name: "last_name",
  placeholder: "Enter last name",
  validation: {
    required: {
      value: true,
      message: "Last name is required",
    },
    pattern: {
      value: /^[A-Za-z]+$/i,
      message: "Last name can only contain alphabetic characters",
    },
  },
};

export const registerPassword = {
  label: "Password",
  type: "password",
  name: "password",
  placeholder: "Enter Password",
  validation: {
    required: {
      value: true,
      message: "Password is required",
    },
    minLength: {
      value: 8,
      message: "Password must be at least 8 characters long",
    },
    pattern: {
      value:
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()-_=+{};:,<.>]).{8,}$/,
      message:
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
    },
  },
};

export const confirmPassword = {
  label: "Confirm Password",
  type: "password",
  name: "confirmPassword",
  placeholder: "Confirm Password",
  validation: {
    required: {
      value: true,
      message: "Confirm password is required",
    },
    validate: (value, { password }) => {
      return value === password || "Password & Confirm password doesn't match";
    },
  },
};

export const phoneField = {
  label: "Phone Number",
  type: "tel",
  name: "phone_number",
  placeholder: "Enter Phone Number",
  validation: {
    required: {
      value: true,
      message: "Phone number is required",
    },
  },
};

export const gender = {
  label: "Gender",
  options: [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ],
  validation: {
    required: {
      value: true,
      message: "Phone number is required",
    },
  },
  name: 'gender',
  multiplr: false
};

export const videoTypeName = {
  label: 'Name',
  type: 'text',
  name: 'name',
  placeholder: 'Name',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const videoTypeNameArabic = {
  label: 'اسم',
  type: 'text',
  name: 'name_ar',
  dir: 'rtl',
  placeholder: 'اسم',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const videoTypePrice = {
  type: 'number',
  placeholder: 'Price',
  label: 'Price',
  name: 'price',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const videoTypePriceArabic = {
  type: 'number',
  placeholder: 'سعر',
  label: 'سعر',
  name: 'price_ar',
  dir: 'rtl',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const videoTypeMedia = {
  type: 'file',
  placeholder: 'Add Media',
  label: 'Add New Media',
  name: 'media',
  accept: "video/*",
  multiple: true,
  validation: {
      required: {
          value: false,
          message: 'required',
      },
  },
}

export const videoDurationName = {
  label: 'Duration',
  type: 'text',
  name: 'duration',
  placeholder: 'Duration',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const videoDurationNameArabic = {
  label: 'اسم',
  type: 'text',
  name: 'name_ar',
  dir: 'rtl',
  placeholder: 'اسم',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const videoDurationPrice = {
  type: 'number',
  placeholder: 'Price',
  label: 'Price',
  name: 'price',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const videoDurationPriceArabic = {
  type: 'number',
  placeholder: 'سعر',
  label: 'سعر',
  name: 'price_ar',
  dir: 'rtl',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const videoAspectRatioName = {
  label: 'Name',
  type: 'text',
  name: 'name',
  placeholder: 'Name',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const videoAspectRatioNameArabic = {
  label: 'اسم',
  type: 'text',
  name: 'name_ar',
  dir: 'rtl',
  placeholder: 'اسم',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const videoAspectRatio = {
  type: 'text',
  placeholder: 'Ratio',
  label: 'Ratio',
  name: 'ratio',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const videoAspectRatioArabic = {
  type: 'number',
  placeholder: 'سعر',
  label: 'سعر',
  name: 'price_ar',
  dir: 'rtl',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const videoAspectRatioMedia = {
  type: 'file',
  placeholder: 'Add Media',
  label: 'Add New Media',
  name: 'media',
  accept: "image/*",
  multiple: false,
  validation: {
      required: {
          value: false,
          message: 'required',
      },
  },
}

export const inHouseServiceMedia = {
  type: 'file',
  placeholder: 'Add Media',
  label: 'Add New Media',
  name: 'media',
  accept: "video/*",
  multiple: false,
  validation: {
      required: {
          value: false,
          message: 'required',
      },
  },
}

export const creatorcriteriatags = {
  label: 'Tags',
  type: 'text',
  name: 'tags',
  placeholder: 'Tags',
  multiline: true,
  validation: {
      required: {
          value: true,
          message: 'required',
      },
  },
}

export const authenticPhotosMedia = {
  type: 'file',
  placeholder: 'Media',
  label: 'Media',
  name: 'media',
  accept: "image/*",
  multiple: false,
  validation: {
      required: {
          value: false,
          message: 'required',
      },
  },
}

export const brandWebsite = {
  label: 'Brand Website',
  type: 'text',
  name: 'url',
  placeholder: 'https://www.brand.com',
  validation: {
      required: {
          value: true,
          message: 'required',
      },
      pattern: {
          value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
          message: 'Please enter a valid URL',
      },
  },
};

export const brandLogo = {
  label: '',
  type: 'file',
  name: 'image',
  placeholder: 'logo',
  validation: {
      required: {
          value: false,
          message: 'required',
      },
  },
};

export const brandCoverImage = {
  label: '',
  type: 'file',
  name: 'cover_image',
  placeholder: 'cover',
  validation: {
      required: {
          value: false,
          message: 'required',
      },
  },
};

export const brandDescription = {
  label: 'Brand Description',
  type: 'text',
  name: 'description',
  placeholder: 'Brand Description',
  validation: {
      required: {
          value: false,
          message: 'required',
      },
  },
}

export const facebook = {
  label: 'Facebook URL',
  type: 'text',
  name: 'facebook_url',
  placeholder: 'Facebook URL',
  validation: {
      required: {
          value: false,
          message: 'required',
      },
  },
}

export const instagram = {
  label: 'Instagram URL',
  type: 'text',
  name: 'instagram_url',
  placeholder: 'Instagram URL',
  validation: {
      required: {
          value: false,
          message: 'required',
      },
  },
}