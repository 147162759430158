import React, { useState } from "react";
import MasterLayout from "../../../Components/Common/Layout/MasterLayout";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../Components/Common/Form/Input/Input";
import { useCreatorsCriteriaAddMutation } from "../../../store/Services/API";
import { useDispatch } from "react-redux";
import {
  setShowToaster,
  setToasterMessage,
  setToasterType,
} from "../../../store/Slice/mainSlice";
import { useNavigate } from "react-router-dom";
import {
  creatorcriteriatags,
  videoTypeName,
} from "../../../Utils/InputValidations";
import Button from "../../../Components/Common/Button";

function CreatorCriteriaAdd() {
  const [loader, setLoader] = useState(false);
  const methods = useForm();
  const [addCreatorCriteria] = useCreatorsCriteriaAddMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('name', data.name);
    let tags = data.tags.split(",")
    tags.forEach((tag, index) => {
      formData.append(`tags[${index}]`, tag);
    });
    try {
      const response = await addCreatorCriteria(formData);
      if (response.data.status) {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Success"));
        navigate(`/admin/creator-criteria`);
        setLoader(false);
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Error"));
        setLoader(false);
      }
    } catch (error) {
      dispatch(setShowToaster(true));
      dispatch(
        setToasterMessage("Something went wrong. Please try again later.")
      );
      dispatch(setToasterType("Error"));
      setLoader(false);
    }
  };
  return (
    <MasterLayout>
      <div className="bg-white shadow-lg p-4">
        <h5 className="mb-10 uppercase">Add Creator Criteria</h5>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 sm:col-span-6">
                <Input {...videoTypeName} />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Input {...creatorcriteriatags} />
                <small className="text-[#817efd]">
                  To add tags please enter words with comma, i.e
                  Tag-1,Tag-2,Tag-3
                </small>
              </div>
              <div className="col-span-12">
                <div className="sm:w-24 ml-auto">
                  <Button text="Add" loader={loader} />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </MasterLayout>
  );
}

export default CreatorCriteriaAdd;
