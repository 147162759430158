import React, { useState } from "react";
import AuthLayout from "../../Components/Common/Layout/AuthScreensLayout";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../Components/Common/Form/Input/Input";
import { useDispatch } from "react-redux";
import { useRegisterMutation } from "../../store/Services/API";
import {
  setIsLoggedIn,
  setShowToaster,
  setToasterMessage,
  setToasterType,
  setUserDetails,
  setUserEmail,
} from "../../store/Slice/mainSlice";
import {
  Email,
  confirmPassword,
  firstName,
  gender,
  lastName,
  phoneField,
  registerPassword,
} from "../../Utils/InputValidations";
import CustomPhoneInput from "../../Components/Common/Form/Input/PhoneInput";
import SelectDropdown from "../../Components/Common/Form/SelectDropdown/SelectDropdown";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../Components/Common/Button";

function Register() {
  const [loader, setLoader] = useState(false);
  const methods = useForm();
  const dispatch = useDispatch();
  const [register] = useRegisterMutation();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoader(true);
    try {
      const response = await register(data);
      if (response?.data?.status) {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Success"));
        dispatch(setUserEmail(data.email))
        navigate('/otp-verification', { state: { url: `login` } })
        setLoader(false);
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterMessage("Error"));
        setLoader(false);
      }
    } catch (error) {
      console.log("hello");
      dispatch(setShowToaster(true));
      dispatch(
        setToasterMessage("Something went wrong. Please try again later.")
      );
      dispatch(setToasterType("Error"));
      setLoader(false);
    }
  };
  return (
    <AuthLayout title="Register">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6">
              <Input {...firstName} />
            </div>
            <div className="col-span-6">
              <Input {...lastName} />
            </div>
            <div className="col-span-12">
              <Input {...Email} />
            </div>
            <div className="col-span-6">
              <Input {...registerPassword} />
            </div>
            <div className="col-span-6">
              <Input {...confirmPassword} />
            </div>
            <div className="col-span-6">
              <CustomPhoneInput {...phoneField} />
            </div>
            <div className="col-span-6">
              <SelectDropdown {...gender} />
            </div>
          </div>
          <Button loader={loader} text='Sign up' />
        </form>
      </FormProvider>
      <p className="text-center text-sm mt-5">
        Already have an account?{" "}
        <Link className="text-[#817EFD]" to={"/login"}>
          Login
        </Link>
      </p>
      <div className="flex items-center my-4">
        <div className="flex-grow border-t border-[#817EFD]"></div>
        <span className="px-4 text-gray-500">or</span>
        <div className="flex-grow border-t border-[#817EFD]"></div>
      </div>
      <div className="flex justify-between items-center">
        <button className="flex flex-none w-[49%] md:w-[46%] text-sm md:text-base items-center justify-center bg-white text-[#757575] border border-[#ddd] rounded-lg py-2 px-2 md:px-4 h-12">
          <img
            src="/assets/images/google-logo.png"
            className="w-5 h-5 mr-2"
            alt="google logo"
          />{" "}
          Sign-up with Google
        </button>
        <button className="flex flex-none w-[49%] md:w-[46%] text-sm md:text-base items-center justify-center bg-white text-[#757575] border border-[#ddd] rounded-lg py-2 px-2 md:px-4 h-12">
          <img
            src="/assets/images/apple-logo.png"
            className="w-5 h-5 mr-2"
            alt="google logo"
          />{" "}
          Sign-up with Apple
        </button>
      </div>
    </AuthLayout>
  );
}

export default Register;
