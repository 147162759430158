import React, { useEffect, useState } from "react";
import Card from "../MainCard";
import { useGetAuthenticPhotosQuery } from "../../../../store/Services/API";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAuthenticPhotosId } from "../../../../store/Slice/mainSlice";

function AuthenticPhotos({ setVideoPrice, authenticPhotosId = null }) {
  const { data, isFetching } = useGetAuthenticPhotosQuery();
  const [authenticPhotos, setAuthenticPhotos] = useState([]);
  const AuthenticPhotoId = useSelector(
    (state) => state.mainSlice.selectedAuthenticPhotosId
  );
  const dispatch = useDispatch();

  const handleInHouseServices = (id, price) => {
    dispatch(setSelectedAuthenticPhotosId(id));
    setVideoPrice((prevState) => ({
      ...prevState,
      authenticPhotos: price,
    }));
  };

  useEffect(() => {
    if (data?.data) {
      setAuthenticPhotos(data?.data);
      if (authenticPhotosId === null) {
        dispatch(setSelectedAuthenticPhotosId(data?.data?.find((x) => x.status === 1)?.id));
        setVideoPrice((prevState) => ({
          ...prevState,
          authenticPhotos: data?.data[0]?.price,
        }));
      }
    }
  }, [data, data?.data, dispatch, isFetching, AuthenticPhotoId]);

  useEffect(() => {
    if (data?.data) {
      setAuthenticPhotos(data?.data);
      if (authenticPhotosId !== null) {
        dispatch(setSelectedAuthenticPhotosId(data?.data?.find((x) => x.id === authenticPhotosId)?.id));
        setVideoPrice((prevState) => ({
          ...prevState,
          authenticPhotos: data?.data?.find((x) => x.id === authenticPhotosId)?.price,
        }));
      }
    }
  }, [data, data?.data, dispatch, isFetching, authenticPhotosId]);

  return (
    <Card title="Authentic photos">
      <div className="grid grid-cols-12 gap-4 mt-4">
        {authenticPhotos?.map((x) => (
          <div className="shadow-lg col-span-12 sm:col-span-3 py-2 px-3">
            {x.status !== 0 && (
              <>
                <div
                  className=""
                  key={x.id}
                  
                >
                  <img
                    src={`${process.env.REACT_APP_ASSET_URL}${x.media}`}
                    alt="Authentic"
                    className="w-full"
                  />
                </div>
                <div className="col-span-12 sm:col-span-3">
                  <div
                    className="authentic_photos_description"
                    dangerouslySetInnerHTML={{
                      __html: x?.description,
                    }}
                  />
                  <p className="text-2xl font-semibold mt-2">
                    +{x?.price}{" "}
                    <span className="text-sm text-gray-400 font-normal">
                      /each video
                    </span>{" "}
                  </p>
                  <button className="mt-4 rounded-full border px-3 py-1 border-[#817EFD] bg-[#817EFD]/20 text-[#817EFD]" onClick={() => handleInHouseServices(x.id, x.price)}>
                    {AuthenticPhotoId === x.id ? "Added" : "Add"}
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </Card>
  );
}

export default AuthenticPhotos;
