import React from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { useSelector } from "react-redux";

function MasterLayout({ children }) {
  const sidebarEnabled = useSelector((state) => state.mainSlice.sidebarEnabled);
  return (
    <div>
      <div className="block sm:hiddem">{sidebarEnabled && <Sidebar />}</div>
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <Navbar />
      <div className="md:w-calc-full-minus-16rem ml-auto p-5 mt-[58px] min-h-calc-full-minus-58px">
        {children}
      </div>
    </div>
  );
}

export default MasterLayout;
