import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  MdDashboard,
  MdVideocam,
  MdAspectRatio,
  MdDesignServices,
  MdFormatListBulleted,
  MdInsertPhoto,
  MdOutlineCategory,
} from "react-icons/md";
import { FaStopwatch, FaUserLarge, FaBriefcase } from "react-icons/fa6";
import { FaTasks } from "react-icons/fa";
import { BiTask } from "react-icons/bi";
import { setSidebarEnabled } from "../../store/Slice/mainSlice";

function Sidebar() {
  const userDetails = useSelector((state) => state.mainSlice.userDetails);
  const currentUserRole = userDetails?.role?.name;
  const dispatch = useDispatch()
  const sidebarRef = useRef(null);


  const adminLinks = [
    {
      url: "/admin/dashboard",
      title: "Dashboard",
      icon: <MdDashboard fontSize='20px' />,
    },
    {
      url: "/admin/video-type",
      title: "Video Type",
      icon: <MdVideocam fontSize='20px' />,
    },
    {
      url: "/admin/video-duration",
      title: "Video Duration",
      icon: <FaStopwatch fontSize='20px' />,
    },
    {
      url: "/admin/video-aspect-ratio",
      title: "Aspect Ratio",
      icon: <MdAspectRatio fontSize='20px' />,
    },
    {
      url: "/admin/inhouse-services",
      title: "In-House Services",
      icon: <MdDesignServices fontSize='20px' />,
    },
    {
      url: "/admin/creators",
      title: "Creators",
      icon: <FaUserLarge />,
    },
    {
      url: "/admin/creator-criteria",
      title: "Creator Criteria",
      icon: <MdFormatListBulleted fontSize='20px' />,
    },
    {
      url: "/admin/authentic-photos",
      title: "Authentic Photos",
      icon: <MdInsertPhoto fontSize='20px' />,
    },
    {
      url: "/admin/categories",
      title: "Category",
      icon: <MdOutlineCategory fontSize='20px' />,
    },
    {
      url: "/admin/tasks",
      title: "Tasks",
      icon: <FaTasks fontSize='20px' />,
    },
    {
      url: "/admin/brand-categories",
      title: "Brand Categories",
      icon: <MdOutlineCategory fontSize='20px' />,
    },
  ];

  const userLinks = [
    {
      url: "/brands/dashboard",
      title: "Dashboard",
      icon: <MdDashboard fontSize='20px' />,
    },
    {
      url: "/brands/campaigns",
      title: "Tasks",
      icon: <BiTask fontSize='20px' />,
    },
    {
      url: "/brands/brand-detail",
      title: "Brand",
      icon: <FaBriefcase fontSize='20px' />,
    },
  ]

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
  //       dispatch(setSidebarEnabled(false));
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [dispatch]);

  return (
    <aside
      id="default-sidebar"
      className="fixed top-[58px] left-0 z-40 w-64 h-calc-full-minus-58px transition-transform sm:translate-x-0 shadow-2xl"
      aria-label="Sidebar"
      ref={sidebarRef}
    >
      <div className="h-full px-3 py-4 overflow-y-auto bg-white">
        {currentUserRole === "admin" ? (
          <ul className="space-y-2 pl-0 font-medium">
            {adminLinks?.map((x, i) => (
              <li className="list-none" key={i}>
                <NavLink
                  to={x.url}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? ""
                      : isActive
                      ? "text-white bg-[#817efd]/70  flex items-center p-2 rounded-lg group cursor-pointer"
                      : "text-gray-900 hover:bg-[#817efd]/70 hover:text-white flex items-center p-2 rounded-lg group cursor-pointer"
                  }
                >
                  {x.icon}
                  <span className="ms-3">{x.title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        ) : (
          <ul className="space-y-2 pl-0 font-medium">
            {userLinks?.map((x, i) => (
              <li className="list-none" key={i}>
                <NavLink
                  to={x.url}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? ""
                      : isActive
                      ? "text-white bg-[#817efd]/70  flex items-center p-2 rounded-lg group cursor-pointer"
                      : "text-gray-900 hover:bg-[#817efd]/70 hover:text-white flex items-center p-2 rounded-lg group cursor-pointer"
                  }
                >
                  {x.icon}
                  <span className="ms-3">{x.title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </div>
    </aside>
  );
}

export default Sidebar;
