import React, { useState } from "react";
import AuthLayout from "../../Components/Common/Layout/AuthScreensLayout";
import Input from "../../Components/Common/Form/Input/Input";
import { Email, Password } from "../../Utils/InputValidations";
import { useForm, FormProvider } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../store/Services/API";
import { useDispatch } from "react-redux";
import {
  setIsLoggedIn,
  setShowToaster,
  setToasterMessage,
  setToasterType,
  setUserDetails,
} from "../../store/Slice/mainSlice";
import Button from "../../Components/Common/Button";

function Login() {
  const methods = useForm();
  const location = useLocation();
  const [login] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { from } = location.state || "";

  const onSubmit = async (data) => {
    setLoader(true);
    try {
      const response = await login(data);
      if (response?.data?.status) {
        if (from && from.pathname) {
          navigate(from.pathname);
        }
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Success"));
        localStorage.setItem("biloUser", JSON.stringify(response?.data?.data));
        localStorage.setItem(
          "biloToken",
          JSON.stringify(response?.data?.token)
        );
        dispatch(setIsLoggedIn(true));
        dispatch(setUserDetails(response.data.data));
        setLoader(false);
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterMessage("Error"));
        setLoader(false);
        if (response.data.message === "Your Email is not verified yet.") {
          navigate("/verify-email", { state: { url: `login` } });
        }
      }
    } catch (error) {
      dispatch(setShowToaster(true));
      dispatch(
        setToasterMessage("Something went wrong. Please try again later.")
      );
      dispatch(setToasterType("Error"));
      setLoader(false);
    }
  };

  return (
    <AuthLayout title="Login">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <Input {...Email} />
          </div>
          <div>
            <Input {...Password} />
          </div>
          <p className="text-[#817EFD] text-sm -mt-3 text-right">
            <Link to={"/verify-email"} state={{ url: `forgot-password` }}>
              Forgot password?
            </Link>
          </p>
          <Button loader={loader} text="Sign In" />
        </form>
      </FormProvider>
      <p className="text-center text-sm mt-5">
        Don't have an account?{" "}
        <Link className="text-[#817EFD]" to={"/register"}>
          Register
        </Link>
      </p>
      <div className="flex items-center my-4">
        <div className="flex-grow border-t border-[#817EFD]"></div>
        <span className="px-4 text-gray-500">or</span>
        <div className="flex-grow border-t border-[#817EFD]"></div>
      </div>
      <div className="flex justify-between items-center">
        <button className="flex flex-none w-[49%] md:w-[46%] text-sm md:text-base items-center justify-center bg-white text-[#757575] border border-[#ddd] rounded-lg py-2 px-2 md:px-4 h-12">
          <img
            src="/assets/images/google-logo.png"
            className="w-5 h-5 mr-2"
            alt="google logo"
          />{" "}
          Sign in with Google
        </button>
        <button className="flex flex-none w-[49%] md:w-[46%] text-sm md:text-base items-center justify-center bg-white text-[#757575] border border-[#ddd] rounded-lg py-2 px-2 md:px-4 h-12">
          <img
            src="/assets/images/apple-logo.png"
            className="w-5 h-5 mr-2"
            alt="google logo"
          />{" "}
          Sign in with Apple
        </button>
      </div>
    </AuthLayout>
  );
}

export default Login;
