import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion";
import { BiErrorCircle } from "react-icons/bi";
import Select from "react-select";

function SelectDropdown({ label, required, options, name, multiple, value }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  // Function to find the option by value
  const getSelectedOption = (value) => {
    if (multiple) {
      return options.filter((option) => value.includes(option.value));
    }
    return options.find((option) => option.value === value);
  };

  return (
    <div>
      <div>
        <label htmlFor={name}>{label}</label>
      </div>
      <Controller
        control={control}
        name={name}
        defaultValue={value?.some((x) => x?.value === 'null' || x?.value === 'undefined' || x?.value === '') ? null : value}
        rules={{ required: required && "This field is required" }}
        render={({ field }) => (
          <Select
            {...field}
            options={options}
            isMulti={multiple}
            isDisabled={!options || options.length === 0}
            classNames={{
              control: (state) =>
                state.isFocused
                  ? "!bg-[#F9F9F9] border !border-[#CCCCCC] !rounded-lg !shadow-none !outline-none w-full text-sm text-[#989898] h-12 pl-2"
                  : "!bg-[#F9F9F9] border !border-[#CCCCCC] !rounded-lg !shadow-none !outline-none w-full text-sm text-[#989898] h-12 pl-2",
            }}
            value={field.value}
            onChange={(selectedOption) =>
              field.onChange(
                multiple
                  ? selectedOption.map((option) => option.value)
                  : selectedOption.value
              )
            }
          />
        )}
      />
      <AnimatePresence>
        {errors[name] && <InputError message={errors[name].message} />}
      </AnimatePresence>
    </div>
  );
}

export default SelectDropdown;

const InputError = ({ message }) => {
  return (
    <motion.p className="text-red-500 text-sm mt-1" {...framer_error}>
      <BiErrorCircle className="inline-block mr-1" />
      {message}
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};
