import React, { useMemo, useEffect, useState } from "react";
import { AiFillSave, AiOutlineClose, AiFillDelete } from "react-icons/ai";
import { FormProvider, useForm } from "react-hook-form";
import countryList from "react-select-country-list";
import { BiImageAdd } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../Components/Common/Form/Input/Input";
import SelectDropdown from "../../Components/Common/Form/SelectDropdown/SelectDropdown";
import {
  setShowToaster,
  setToasterMessage,
  setToasterType,
} from "../../store/Slice/mainSlice";
import {
  useEditBrandMutation,
  useGetBrandCategoriesQuery,
} from "../../store/Services/API";
import {
  brandCoverImage,
  brandDescription,
  brandLogo,
  brandWebsite,
  facebook,
  instagram,
  videoTypeName,
} from "../../Utils/InputValidations";
import MasterLayout from "../../Components/Common/Layout/MasterLayout";
import { Link } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";

function EditBrand({ setDetailsEditable }) {
  const { handleSubmit, setValue, getValues, ...methods } = useForm();
  const options = useMemo(() => countryList().getData(), []);
  const userBrands = useSelector((state) => state.mainSlice.userBrands);
  const selectedBrandIndex = useSelector(
    (state) => state.mainSlice.selectedUserBrand
  );
  const { data, isFetching } = useGetBrandCategoriesQuery();
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const [logo, setLogo] = useState("");
  const [logoBase64, setLogoBase64] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [coverImageBase64, setCoverImageBase64] = useState("");
  const [editBrand] = useEditBrandMutation();

  const convertImageToBase64 = async (image) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(image);
    });
  };

  const EditBrand = async (data) => {
    let request = {
      data: {
        category: data?.category || null,
        country: data?.country?.label || null,
        cover_image: coverImageBase64 || null,
        logo: logoBase64 || null,
        description: data?.description || null,
        facebook_url: data?.facebook_url || null,
        instagram_url: data?.instagram_url || null,
        name: data?.name || null,
        url: data?.url || null,
      },
      id: userBrands[selectedBrandIndex]?.id,
    };

    try {
      const response = await editBrand(request);
      if (response.data.status === true) {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Success"));
        setDetailsEditable(false);
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Error"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = handleSubmit((data) => {
    EditBrand(data);
  });

  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    setLogo(file);
    const base64 = await convertImageToBase64(file);
    setLogoBase64(base64);
  };

  const handleCoverImageChange = async (e) => {
    const file = e.target.files[0];
    setCoverImage(file);
    const base64 = await convertImageToBase64(file);
    setCoverImageBase64(base64);
  };

  useEffect(() => {
    if (data) {
      setCategories(data?.data?.map((x) => ({ value: x.id, label: x.name })));
    }
  }, [data]);

  useEffect(() => {
    const imageProcess = async () => {
      if (logo) {
        const logoBase = await convertImageToBase64(logo);
        setLogoBase64(logoBase);
      }
      if (coverImage) {
        const coverImageBase = await convertImageToBase64(coverImage);
        setCoverImageBase64(coverImageBase);
      }
    };

    imageProcess();
  }, [logo, coverImage]);

  return (
    <MasterLayout>
      {userBrands.length > 0 ? (
        <div className="sm:p-5">
          <div className="grid grid-cols-12">
            <FormProvider {...methods}>
              <form
                onSubmit={(e) => e.preventDefault()}
                noValidate
                className="col-span-12 sm:col-span-8"
              >
                <div className="col-span-12">
                  <div className="flex gap-4 items-center">
                    <button
                      className="bg-[#817efd] text-white flex items-center gap-2 rounded-full py-2 px-5"
                      onClick={() => onSubmit()}
                    >
                      <AiFillSave /> Save
                    </button>
                    <Link
                      to={"/brands/brand-detail"}
                      className="rounded-full text-[#808080] px-5 py-2 flex items-center gap-2"
                    >
                      <AiOutlineClose /> Cancel
                    </Link>
                  </div>
                </div>
                <div className="col-span-8">
                  <div className={`bg-white p-3 mt-5`}>
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12">
                        <label htmlFor="">Logo</label>
                        <div className="flex gap-2">
                          {(logoBase64 || userBrands[selectedBrandIndex]?.logo) && (
                            <div>
                              <img
                                src={logoBase64 || `${process.env.REACT_APP_ASSET_URL}${userBrands[selectedBrandIndex]?.logo}`}
                                className="h-28 w-28 rounded-lg"
                                alt=""
                              />
                            </div>
                          )}
                          <div className="w-28 h-28 border rounded-lg relative border-[#817efd] cursor-pointer">
                            <div className="">
                              <Input
                                {...brandLogo}
                                handleImageChange={handleLogoChange}
                                classes="absolute w-28 h-28 -mt-4 opacity-0 cursor-pointer"
                              />
                            </div>
                            <div className="flex flex-col items-center justify-center object-cover">
                              <BiImageAdd className="mt-[10px] text-[30px]" />
                              <span className="text-sm">Upload</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-12">
                        <label htmlFor="">Cover Image</label>
                        <div className="flex gap-2">
                          <div>
                            {(coverImageBase64 || userBrands[selectedBrandIndex]?.cover_image) && (
                              <img
                                src={coverImageBase64 || `${process.env.REACT_APP_ASSET_URL}${userBrands[selectedBrandIndex]?.cover_image}`}
                                className="w-28 h-28 rounded-lg"
                              />
                            )}
                          </div>
                          <div className="w-28 h-28 border rounded-lg relative border-[#817efd] cursor-pointer">
                            <div className="">
                              <Input
                                {...brandCoverImage}
                                handleImageChange={handleCoverImageChange}
                                classes="absolute w-28 h-28 -mt-4 opacity-0 cursor-pointer"
                              />
                            </div>
                            <div className="flex flex-col items-center justify-center object-cover">
                              <BiImageAdd className="mt-[10px] text-[30px]" />
                              <span className="text-sm">Upload</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-12">
                        <Input
                          {...videoTypeName}
                          value={userBrands[selectedBrandIndex]?.name}
                        />
                      </div>
                      <div className="col-span-12">
                        <SelectDropdown
                          label="Brand Category"
                          required={true}
                          options={categories}
                          name="category"
                          value={[
                            {
                              value:
                                userBrands[selectedBrandIndex]?.brand_category
                                  ?.id,
                              label:
                                userBrands[selectedBrandIndex]?.brand_category
                                  ?.name,
                            },
                          ]}
                        />
                      </div>
                      <div className="col-span-12">
                        <SelectDropdown
                          label="Brand Country"
                          required={true}
                          options={options}
                          name="country"
                          value={[
                            {
                              value: `${userBrands[selectedBrandIndex]?.country}`,
                              label: `${userBrands[selectedBrandIndex]?.country}`,
                            },
                          ]}
                        />
                      </div>
                      <div className="col-span-12">
                        <Input
                          {...brandWebsite}
                          value={userBrands[selectedBrandIndex]?.url}
                        />
                      </div>
                      <div className="col-span-12">
                        <Input
                          {...brandDescription}
                          value={userBrands[selectedBrandIndex]?.description}
                        />
                      </div>
                      <div className="col-span-6">
                        <Input
                          {...facebook}
                          value={userBrands[selectedBrandIndex]?.facebook_url}
                        />
                      </div>
                      <div className="col-span-6">
                        <Input
                          {...instagram}
                          value={userBrands[selectedBrandIndex]?.instagram_url}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      ) : (
        <ThreeCircles
          visible={true}
          height="60"
          width="60"
          color="#000"
          ariaLabel="three-circles-loading"
          wrapperStyle={{}}
          wrapperClass="flex flex-col items-center h-screen justify-center"
        />
      )}
    </MasterLayout>
  );
}

export default EditBrand;
