import React, { useState } from "react";
import MasterLayout from "../../../Components/Common/Layout/MasterLayout";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setShowToaster,
  setToasterMessage,
  setToasterType,
} from "../../../store/Slice/mainSlice";
import { useCreatorsEditMutation } from "../../../store/Services/API";
import Input from "../../../Components/Common/Form/Input/Input";
import {
  inHouseServiceMedia,
  videoTypeName,
  videoTypePrice,
} from "../../../Utils/InputValidations";
import TextEditor from "../../../Components/Common/Form/TextEditor/TextEditor";
import VideoThumbnail from "../../../Components/Common/Campaign/VideoThumbnail";
import ModalLayout from "../../../Components/Common/Layout/ModelLayout";
import { MdClose } from "react-icons/md";
import VideoPlayer from "../../../Components/Common/VideoPlayer";
import Button from "../../../Components/Common/Button";

function CreatorsEdit() {
  const [selectedImage, setSelectedImage] = useState([]);
  const [loader, setLoader] = useState(false);
  const [editCreators] = useCreatorsEditMutation();
  const creatorsToEdit = useSelector(
    (state) => state.mainSlice.creatorsToEdit
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPlayer, setIsPlayer] = useState({
    open: false,
    url: "",
  });

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImage(files);
  };

  const methods = useForm();

  const getDescription = (value) => {
    methods.setValue("description", value);
  };

  const updatePlayerState = (newState) => {
    setIsPlayer((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const openPlayer = (url) => {
    updatePlayerState({ open: true, url });
  };

  const closePlayer = () => {
    updatePlayerState({ open: false, url: "" });
  };

  const onSubmit = async (data) => {
    setLoader(true);
    const formData = new FormData();
    console.log(data.price)
    formData.append("name", data.name);
    formData.append("price", data.price);
    formData.append("description", data.description);
    selectedImage.forEach((tag, index) => {
      formData.append(`media[${index}]`, tag);
    });
    try {
      const response = await editCreators({
        data: formData,
        id: creatorsToEdit.id,
      });
      if (response.data.status) {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Success"));
        navigate(`/admin/creators`);
        setLoader(false);
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Error"));
        setLoader(false);
      }
    } catch (error) {
      dispatch(setShowToaster(true));
      dispatch(
        setToasterMessage("Something went wrong. Please try again later.")
      );
      dispatch(setToasterType("Error"));
      setLoader(false);
    }
  };

  return (
    <MasterLayout>
      <div className="bg-white p-4 shadow-lg">
        <h5 className="mb-10">Editing {creatorsToEdit?.name}</h5>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 sm:col-span-6">
                <Input {...videoTypeName} value={creatorsToEdit?.name} />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Input {...videoTypePrice} value={creatorsToEdit?.price} />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Input
                  {...inHouseServiceMedia}
                  handleImageChange={handleImageChange}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <label className="">Current Media</label>
                <div className="flex mt-2 gap-3">
                  {creatorsToEdit?.medias?.map((x, i) => (
                    <div key={x.id} className={`${i > 2 && "hidden"}`}>
                      {i <= 2 && (
                        <div className="relative">
                          <MdClose
                            className="absolute -left-2 -top-2 z-40 bg-[#dc3545] text-white p-1 rounded-full cursor-pointer"
                            fontSize="20px"
                          />
                          <VideoThumbnail
                            url={x.filename}
                            openPlayer={openPlayer}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-span-12">
                <TextEditor
                  getDescription={getDescription}
                  value={creatorsToEdit?.description}
                />
              </div>
              <div className="col-span-12">
                <div className="sm:w-24 ml-auto">
                  <Button text="Edit" loader={loader} />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      {isPlayer.open && (
        <ModalLayout classes="!p-0">
          <div className="flex justify-end pt-3 pr-3">
            <MdClose
              fontSize="20px"
              className="cursor-pointer"
              onClick={() => closePlayer()}
            />
          </div>
          <VideoPlayer url={isPlayer.url} />
        </ModalLayout>
      )}
    </MasterLayout>
  );
}

export default CreatorsEdit;
