import "./App.css";
import { Route, Routes } from "react-router-dom";
import { GuestRoutes } from "./Utils/GuestRoutes";
import { DecisionType } from "./Utils/UserTypes";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { setShowToaster } from "./store/Slice/mainSlice";
import { AdminRoutes } from "./Utils/AdminRoutes";
import { UserRoutes } from "./Utils/UserRoutes";

const withAccessControl = (Component, acl) => (currentUserRole) => {
  const decision = acl[currentUserRole];
  if (!decision || decision.type === DecisionType.ALLOW) {
    return <Component />;
  } else if (decision.type === DecisionType.REDIRECT) {
    return <Navigate to={decision.meta || "/"} />;
  }
  return null;
};

function App() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.mainSlice.userDetails);
  const showToaster = useSelector((state) => state.mainSlice.showToaster);
  const toasterType = useSelector((state) => state.mainSlice.toasterType);
  const toasterMessage = useSelector((state) => state.mainSlice.toasterMessage);
  const currentUserRole = userDetails?.role?.name || "guest";

  useEffect(() => {
    if (showToaster) {
      if (toasterType === "Success") {
        toast.success(toasterMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(toasterMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
    dispatch(setShowToaster(false));
  }, [dispatch, showToaster, toasterMessage, toasterType]);

  return (
    <div className="bg-[#f6f9fa] min-h-screen">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Routes>
        {GuestRoutes.map(({ path, component, exact, acl }) => (
          <Route
            key={path}
            path={path}
            exact={exact}
            element={withAccessControl(component, acl)(currentUserRole)}
          />
        ))}
        {AdminRoutes.map(({ path, component, exact, acl }) => (
          <Route
            key={path}
            path={path}
            exact={exact}
            element={withAccessControl(component, acl)(currentUserRole)}
          />
        ))}
        {UserRoutes.map(({ path, component, exact, acl }) => (
          <Route
            key={path}
            path={path}
            exact={exact}
            element={withAccessControl(component, acl)(currentUserRole)}
          />
        ))}
      </Routes>
    </div>
  );
}

export default App;
