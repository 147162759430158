import React, { useState } from "react";
import MasterLayout from "../../../Components/Common/Layout/MasterLayout";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../Components/Common/Form/Input/Input";
import { creatorcriteriatags, videoTypeName } from "../../../Utils/InputValidations";
import { useNavigate } from "react-router-dom";
import { useCreatorsCriteriaEditMutation } from "../../../store/Services/API";
import { setShowToaster, setToasterMessage, setToasterType } from "../../../store/Slice/mainSlice";
import Button from "../../../Components/Common/Button";

function CreatorCriteriaEdit() {
  const creatorCriteriaToEdit = useSelector((state) => state.mainSlice.creatorCriteriaToEdit);
  const [loader, setLoader] = useState(false);
  const methods = useForm();
  const [editCreatorCriteria] = useCreatorsCriteriaEditMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('name', data.name);
    let tags = data.tags.split(",")
    tags.forEach((tag, index) => {
      formData.append(`tags[${index}]`, tag);
    });
    try {
      const response = await editCreatorCriteria({data: formData, id: creatorCriteriaToEdit.id});
      if (response.data.status) {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Success"));
        navigate(`/admin/creator-criteria`);
        setLoader(false);
      } else {
        dispatch(setShowToaster(true));
        dispatch(setToasterMessage(response.data.message));
        dispatch(setToasterType("Error"));
        setLoader(false);
      }
    } catch (error) {
      dispatch(setShowToaster(true));
      dispatch(
        setToasterMessage("Something went wrong. Please try again later.")
      );
      dispatch(setToasterType("Error"));
      setLoader(false);
    }
  };
  return (
    <MasterLayout>
      <div className="bg-white shadow-lg p-4">
        <h5 className="mb-10">Edit Creator Criteria {creatorCriteriaToEdit.name}</h5>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 sm:col-span-6">
                <Input {...videoTypeName} value={creatorCriteriaToEdit.name} />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Input {...creatorcriteriatags} value={creatorCriteriaToEdit?.tags?.map((x) => x.tag)} />
                <small className="text-[#817efd]">
                  To add tags please enter words with comma, i.e
                  Tag-1,Tag-2,Tag-3
                </small>
              </div>
              <div className="col-span-12">
                <div className="sm:w-24 ml-auto">
                  <Button text="Edit" loader={loader} />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </MasterLayout>
  );
}

export default CreatorCriteriaEdit;
